import * as React from "react";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";

import cdnUrl from "../../../../CampaignClubs/cdnUrl";
import { Sport, useSelectedSport } from "../../../../common/context/sport";
import ProviderAvatar from "../../Shared/ProviderAvatar";
import { AppListItemProps } from "../types";

const WIDTH = "90%";

function getCdnUrl(name: string, id: number, primary: boolean): string {
    const ptiUrl = cdnUrl("hero-images/PTI.jpg");
    const workoutUrl = cdnUrl(`hero-images/WORKOUT_${id}.jpg`);
    if (primary) {
        if (name.indexOf("PTI") > 0) {
            return cdnUrl("hero-images/PTI.jpg");
        }

        return cdnUrl(`hero-images/WORKOUT_${id}.jpg`);
    }

    const defaultUrl = cdnUrl("hero-images/DEFAULT.jpg");
    if (name.indexOf("PTI") > 0) {
        return `url(${ptiUrl}), url(${defaultUrl})`;
    }
    return `url(${workoutUrl}), url(${defaultUrl})`;
}

export default function HeroWrapper({
    onClick,
    workout,
}: AppListItemProps): JSX.Element | null {
    const { appId, id, name } = workout;

    const { selected, updateSelected } = useSelectedSport();

    const content = (
        <CardMedia
            src={getCdnUrl(name, id, true)}
            sx={{
                aspectRatio: "16 / 9",
                minWidth: WIDTH,
                position: "relative",
                "&.MuiCardMedia-root": {
                    backgroundImage: getCdnUrl(name, id, false),
                },
            }}
        >
            <CardContent
                sx={{
                    pt: 1,
                    pr: 1,
                    pl: 1,
                    pb: 0,
                }}
            >
                <Typography
                    sx={{
                        textShadow: "1px 1px 5px black",
                        whiteSpace: "normal",
                        textTransform: "none",
                    }}
                    variant="h4"
                    component="div"
                    color="white"
                >
                    {name}
                </Typography>
            </CardContent>
            <CardActions
                sx={{
                    minHeight: 48,
                    position: "absolute",
                    bottom: 0,
                }}
            >
                {workout.provider && (
                    <ProviderAvatar
                        providerName={workout.provider.name}
                        providerLabel={workout.provider.label}
                    />
                )}
            </CardActions>
        </CardMedia>
    );

    return (
        <Card
            sx={{
                minWidth: WIDTH,
                aspectRatio: "16 / 9",
            }}
            variant="outlined"
            onClick={() => {
                if (selected !== workout.sportName) {
                    updateSelected(workout.sportName as Sport);
                }
                onClick(
                    workout.id,
                    `/content/apps/workouts/plugin/play/${appId}/${id}`,
                );
            }}
            raised
        >
            {content}
        </Card>
    );
}
