import * as React from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion, { AccordionProps } from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import type {
    PlayerPosition,
    PlayMode,
} from "@volley/shared/apps/app-common-models";
import type { Side } from "@volley/shared/http/side";

import { COURT_WIDTH_MM } from "../../../../util/positionUtil";
import ResizableWorkoutVisualizer from "../../../common/Visualizer/ResizableWorkoutVisualizer";
import {
    VisualizerShot,
    WorkoutForVisualizer,
} from "../../../common/Visualizer/types";
import { useSelectedSport } from "../../../common/context/sport";
import { useLift } from "../../../hooks/useLift";
import usePosition, {
    PositionWithHeight,
    PositionProximity,
} from "../../../hooks/usePosition";
import LocalizationProgress from "../../Localization/LocalizationProgress";
import { mirrorPlayer, mirrorTrainer, playerSide } from "../../Position/util";

const ALLOWED_DIFF = 0.61; // 2 feet from center line in meters

interface LocalizationAccordionProps extends Omit<AccordionProps, "children"> {
    trainerPosition?: PositionWithHeight | undefined;
    playerPosition?: PlayerPosition | undefined;
    hideBoth?: boolean;
    playMode: PlayMode;
    hasLocalized: boolean;
    shots: VisualizerShot[];
    setHasLocalized: (hasLocalized: boolean) => void;
    setPlayMode: (mode: PlayMode) => void;
    onLocalizeRequested: () => void;
}

export default function LocalizationAccordion({
    trainerPosition,
    playerPosition,
    hideBoth,
    playMode,
    hasLocalized,
    shots,
    setHasLocalized,
    setPlayMode,
    onLocalizeRequested,
    ...props
}: LocalizationAccordionProps): JSX.Element {
    const { selected } = useSelectedSport();
    const { safeHeight } = useLift();

    const [showBothToast, setShowBothToast] = React.useState(false);

    const { adKey, deuceKey } = React.useMemo(() => {
        if (playerPosition) {
            const side = playerSide(playerPosition, selected);

            return {
                adKey: (side === "ad" ? "standard" : "mirror") as PlayMode,
                deuceKey: (side === "deuce"
                    ? "standard"
                    : "mirror") as PlayMode,
            };
        }
        return {
            adKey: "standard" as PlayMode,
            deuceKey: "mirror" as PlayMode,
        };
    }, [playerPosition, selected]);

    const bothDisabled = React.useMemo(() => {
        if (
            trainerPosition &&
            selected === "PLATFORM_TENNIS" &&
            Math.abs(trainerPosition.x - COURT_WIDTH_MM / 2) <
                ALLOWED_DIFF * 1000
        ) {
            return false;
        }

        if (
            trainerPosition &&
            selected !== "PLATFORM_TENNIS" &&
            Math.abs(trainerPosition.x) < ALLOWED_DIFF
        ) {
            return false;
        }

        return true;
    }, [trainerPosition, selected]);

    const showBoth = React.useMemo(() => {
        if (bothDisabled && hideBoth) {
            return false;
        }

        return true;
    }, [hideBoth, bothDisabled]);

    const playerPositions = React.useMemo<PlayerPosition[]>(() => {
        if (playerPosition) {
            switch (playMode) {
                case "mirror":
                    return [mirrorPlayer(playerPosition, selected)];
                case "dual":
                    return [
                        playerPosition,
                        mirrorPlayer(playerPosition, selected),
                    ];
                default:
                    return [playerPosition];
            }
        }
        return [];
    }, [playerPosition, selected, playMode]);

    const targetPosition = React.useMemo<PositionWithHeight>(() => {
        if (trainerPosition && playMode === "mirror") {
            return mirrorTrainer(trainerPosition, selected);
        }

        return trainerPosition as PositionWithHeight;
    }, [trainerPosition, selected, playMode]);

    const shotsToShow: VisualizerShot[] = React.useMemo(() => {
        const mapped: VisualizerShot[] = shots.map((s) => ({
            launchSpeed: s.launchSpeed,
            pan: playMode === "mirror" ? -s.pan : s.pan,
            spinDirection: s.spinDirection,
            tilt: s.tilt,
            spinLevel: s.spinLevel,
            spinSpeed: s.spinSpeed,
        }));

        if (playMode === "dual") {
            shots.forEach((s) =>
                mapped.push({
                    launchSpeed: s.launchSpeed,
                    pan: -s.pan,
                    spinDirection: s.spinDirection,
                    tilt: s.tilt,
                    spinLevel: s.spinLevel,
                    spinSpeed: s.spinSpeed,
                }),
            );
        }

        return mapped;
    }, [shots, playMode]);

    const {
        error,
        position,
        improve,
        isLocalizing,
        isVisionStarting,
        isVisionFaulted,
        cancel,
        updatePosition,
        localizationStatus,
        delta,
    } = usePosition();

    const workoutToRender: WorkoutForVisualizer = React.useMemo(
        () => ({
            player: playerPositions,
            shots: shotsToShow,
            trainer: targetPosition,
            localized:
                hasLocalized && position
                    ? { ...position, heightIn: targetPosition.heightIn }
                    : undefined,
        }),
        [position, targetPosition, playerPositions, shotsToShow, hasLocalized],
    );

    React.useEffect(() => {
        // in case of page refresh - this will hide the "Check Position" button so the status shows
        if (isLocalizing || error === "NOT_READY" || error === "FAULTED") {
            setHasLocalized(true);
        }
    }, [isLocalizing, error, setHasLocalized]);

    // cancel localization job if we unmount
    React.useEffect(() => () => cancel(), [cancel]);

    const positionProximity = React.useMemo((): PositionProximity => {
        if (isVisionStarting) {
            return "Not Ready";
        }
        if (isVisionFaulted) {
            return "Unavailable";
        }
        if (hasLocalized && !isLocalizing) {
            if (position === undefined || error) {
                return "Unsure";
            }
            if (improve) {
                return "Improve";
            }
        }
        return "Good";
    }, [
        isVisionStarting,
        isVisionFaulted,
        position,
        error,
        improve,
        hasLocalized,
        isLocalizing,
    ]);

    const summary = React.useMemo(() => {
        let side: Side | "both" = playMode === "dual" ? "both" : "ad";
        if (playerPosition) {
            const defaultSide = playerSide(playerPosition, selected);
            if (playMode === "standard") {
                side = defaultSide;
            }
            if (playMode === "mirror") {
                side = defaultSide === "ad" ? "deuce" : "ad";
            }
        }
        if (props.expanded) {
            return (
                <Box component="div">
                    <Typography color="primary.main" variant="h3">
                        Position
                    </Typography>
                </Box>
            );
        }

        const description = ["TENNIS", "PADEL"].includes(selected)
            ? side
            : `${side}, ${hasLocalized ? positionProximity : "Check"}`;

        return (
            <Box component="div">
                <Typography color="primary.main" variant="h3">
                    Position
                </Typography>
                <Typography color="primary.main" variant="h4">
                    {description}
                </Typography>
            </Box>
        );
    }, [
        playerPosition,
        selected,
        props.expanded,
        playMode,
        hasLocalized,
        positionProximity,
    ]);

    return (
        <Accordion {...props}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                {summary}
            </AccordionSummary>
            <AccordionDetails>
                <Stack spacing={1}>
                    <RadioGroup
                        name="play-mode"
                        row
                        value={playMode}
                        onChange={(e) => {
                            const val = e.target.value;
                            if (val === "dual" && bothDisabled) {
                                setShowBothToast(true);
                            } else {
                                setPlayMode(val as PlayMode);
                                setHasLocalized(false);
                            }
                        }}
                        sx={{
                            display: "flex",
                            justifyContent: "space-evenly",
                        }}
                    >
                        <FormControlLabel
                            value={deuceKey}
                            control={<Radio color="info" />}
                            label={
                                <Typography variant="caption">Deuce</Typography>
                            }
                        />
                        <FormControlLabel
                            value={adKey}
                            control={<Radio color="info" />}
                            label={
                                <Typography variant="caption">Ad</Typography>
                            }
                        />
                        {showBoth && (
                            <FormControlLabel
                                value="dual"
                                control={<Radio color="info" />}
                                label={
                                    <Typography variant="caption">
                                        Both
                                    </Typography>
                                }
                            />
                        )}
                    </RadioGroup>
                    <ResizableWorkoutVisualizer
                        workout={workoutToRender}
                        positionProximity={positionProximity}
                        improveMode="xy"
                    />
                    {selected === "PLATFORM_TENNIS" && (
                        <LocalizationProgress
                            localizationError={error}
                            hintText={positionProximity}
                            cancel={() => cancel()}
                            update={() => {
                                onLocalizeRequested();
                                updatePosition({
                                    ...targetPosition,
                                    heightIn:
                                        trainerPosition?.heightIn ?? safeHeight,
                                });
                            }}
                            localizationStatus={localizationStatus}
                            positionRequested={hasLocalized}
                            delta={delta}
                        />
                    )}
                </Stack>
            </AccordionDetails>
            <Snackbar
                open={showBothToast}
                onClose={() => {
                    setShowBothToast(false);
                }}
                autoHideDuration={4000}
                anchorOrigin={{
                    horizontal: "center",
                    vertical: "top",
                }}
                sx={{
                    top: 100,
                }}
            >
                <Alert severity="warning">
                    Select a workout where the trainer is in the middle of the
                    court to use the both option
                </Alert>
            </Snackbar>
        </Accordion>
    );
}

LocalizationAccordion.defaultProps = {
    trainerPosition: undefined,
    playerPosition: undefined,
    hideBoth: false,
};
