import * as React from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Stack from "@mui/material/Stack";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Typography from "@mui/material/Typography";

import type { AppWorkoutWithRelations as AppWorkout } from "@volley/data/dist/types/app-workout";
import type { AppParameters } from "@volley/shared/app-models";
import type {
    ResponsiveState,
    ResponsiveParameters,
    DifficultyLevel,
    ResponsiveIndexEntry,
} from "@volley/shared/apps/responsive-models";
import { JSONObject } from "@volley/shared/common-models";

import logger from "../../../log";
import { logFetchError } from "../../../util/fetchApi";
import SportPicker from "../../SportPicker";
import NotchedOutline from "../../common/NotchedOutline";
import { useSelectedSport } from "../../common/context/sport";
import { usePhysicsModelContext } from "../../hooks/PhysicsModelProvider";
import { useStatus } from "../../hooks/status";
import usePosition from "../../hooks/usePosition";
import SlimNumberInput from "../util/SlimNumberInput";

import AccordionSlider from "./Shared/AccordionSlider";
import CaptureToast from "./Shared/CaptureToast";
import PlayAppBar from "./Shared/PlayAppBar";
import WorkoutPicker from "./apps/responsive/WorkoutPicker";
import useAppWorkouts from "./db";
import useAppWorkoutPlay from "./useAppWorkoutPlay";

type ParamsAction =
    | { type: "shots"; value: number }
    | { type: "mode"; value: "follow" | "probability" }
    | { type: "difficulty"; value: DifficultyLevel };

function paramsReducer(
    state: ResponsiveParameters,
    action: ParamsAction,
): ResponsiveParameters {
    switch (action.type) {
        case "shots":
            return { ...state, numberOfBalls: action.value };
        case "difficulty":
            return { ...state, difficulty: action.value };
        case "mode":
            return { ...state, mode: action.value, difficulty: "easy" };
        default:
            throw new Error(
                "Invalid action to perform on a Responsive Parameters set.",
            );
    }
}

const defaultParameters: ResponsiveParameters = {
    numberOfBalls: 60,
    initialDelay: 5,
    difficulty: "easy",
    mode: "probability",
};

type KnownBallCount = "1" | "15" | "30" | "45" | "All";
const KnownBallCounts: Record<KnownBallCount, number> = {
    1: 1,
    15: 15,
    30: 30,
    45: 45,
    All: 60,
};

const ballCountMarks = Object.keys(KnownBallCounts).map((k) => ({
    label: k,
    value: KnownBallCounts[k as KnownBallCount],
}));

export default function ResponsivePlayApp(): JSX.Element {
    const [paramsState, paramsDispatch] = React.useReducer(
        paramsReducer,
        defaultParameters,
    );
    const parameters = paramsState as unknown as AppParameters;
    const [workout, setWorkout] = React.useState<AppWorkout | null>(null);
    const [playClicked, setPlayClicked] = React.useState(false);
    const { physicsModelName } = usePhysicsModelContext();
    const { selected: selectedSport } = useSelectedSport();
    const modifiedRef = React.useRef(true);
    const { isVisionStarting } = usePosition();
    const { status } = useStatus();
    const visionUnavailable =
        isVisionStarting || status?.vision?.serviceState !== "Running";
    const [availableWorkouts, setAvailableWorkouts] = React.useState<
        ResponsiveIndexEntry[] | null
    >(null);
    const [selectedWorkout, setSelectedWorkout] = React.useState<string | null>(
        null,
    );

    const { addWorkout, loadResponsiveWorkout, loadResponsiveWorkouts } =
        useAppWorkouts();

    const {
        start,
        pause,
        captureDisabled,
        requestError,
        playInitiated,
        playDisabled,
        pauseDisabled,
        playState,
        statusText,
        workoutState,
        captureVideo,
        captureStatus,
    } = useAppWorkoutPlay({
        workout,
        parameters,
    });

    React.useEffect(() => {
        loadResponsiveWorkouts()
            .then((results) => setAvailableWorkouts(results))
            .catch((e) =>
                logFetchError(
                    e,
                    "Failed to fetch list of responsive workouts.",
                ),
            );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        setSelectedWorkout(null);
        loadResponsiveWorkouts()
            .then((results) => setAvailableWorkouts(results))
            .catch((e) =>
                logFetchError(
                    e,
                    "Failed to fetch list of responsive workouts.",
                ),
            );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSport]);

    const saveWorkout = React.useCallback(async () => {
        if (modifiedRef.current && selectedWorkout) {
            logger.info(`Fetching responsive config for ${selectedWorkout}`);
            const workoutConfig = await loadResponsiveWorkout(selectedWorkout);

            if (workoutConfig === null) {
                logger.error(
                    `Unable to load ${selectedWorkout} for ${selectedSport}`,
                );
            } else {
                logger.info("Saving app workout instance");
                const { trainer, ...config } = workoutConfig;
                const { positionX, positionY, positionHeight } = trainer;
                const newWorkout = await addWorkout({
                    appId: 2,
                    appName: "ResponsivePlay",
                    config: config as unknown as JSONObject,
                    description: "Generated Responsive Play Workout",
                    name: `Responsive Play: ${selectedSport} ${selectedWorkout}`,
                    overview: "",
                    positionHeight,
                    positionX,
                    positionY,
                    positionYaw: 0,
                    state: "BUILD",
                    physicsModelName,
                    sport: { name: selectedSport },
                    contentProviderId: null,
                    copiedFromAppWorkoutId: null,
                    extendedData: null,
                });

                if (newWorkout !== null) {
                    logger.info(
                        `New responsive play workout saved: ${newWorkout.id}`,
                    );
                    setWorkout(newWorkout);
                    setPlayClicked(true);
                    modifiedRef.current = false;
                }
            }
        } else {
            setPlayClicked(true);
        }
    }, [
        selectedWorkout,
        loadResponsiveWorkout,
        addWorkout,
        physicsModelName,
        selectedSport,
    ]);

    const responsiveWorkoutState =
        workoutState as unknown as ResponsiveState | null;
    const ballsThrown = responsiveWorkoutState?.currentShotNumber ?? 0;
    const totalBalls =
        (parameters as unknown as ResponsiveParameters).numberOfBalls ?? 0;
    const summaryText = playInitiated ? `${ballsThrown} of ${totalBalls}` : "";

    const handlePlayClicked = React.useCallback(async () => {
        await saveWorkout();
    }, [saveWorkout]);

    React.useEffect(() => {
        if (playClicked && workout) {
            setPlayClicked(false);
            void start();
        }
    }, [playClicked, workout, start]);

    return (
        <Stack
            sx={{
                pb: "90px",
            }}
        >
            {requestError && (
                <Typography color="error.main">{requestError}</Typography>
            )}
            {visionUnavailable && (
                <Typography color="info.main" variant="caption">
                    Play will be enabled when paired and cameras fully
                    initialized
                </Typography>
            )}
            <Accordion expanded disabled={playState === "playing"}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h4" color="primary.main">
                        {playInitiated
                            ? statusText
                            : "Responsive Play Parameters"}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack spacing={2}>
                        <NotchedOutline label="Sport Select">
                            <SportPicker
                                fullWidth
                                afterSportChanged={() => {
                                    modifiedRef.current = true;
                                }}
                            />
                        </NotchedOutline>
                        <NotchedOutline label="Workout">
                            <WorkoutPicker
                                fullWidth
                                options={availableWorkouts ?? []}
                                selected={selectedWorkout}
                                onChange={(f) => setSelectedWorkout(f)}
                            />
                        </NotchedOutline>
                        <NotchedOutline label="Difficulty">
                            <ToggleButtonGroup
                                disabled={paramsState.mode === "follow"}
                                fullWidth
                                color="primary"
                                value={paramsState.difficulty}
                                exclusive
                                onChange={(_, v) => {
                                    if (v) {
                                        modifiedRef.current = true;
                                        paramsDispatch({
                                            type: "difficulty",
                                            value: v as DifficultyLevel,
                                        });
                                    }
                                }}
                            >
                                <ToggleButton value="easy">Easy</ToggleButton>
                                <ToggleButton value="medium">
                                    Medium
                                </ToggleButton>
                                <ToggleButton value="hard">
                                    Difficult
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </NotchedOutline>
                        <NotchedOutline label="Number of Balls">
                            <Stack>
                                <SlimNumberInput
                                    onChange={(value) => {
                                        modifiedRef.current = true;
                                        paramsDispatch({
                                            type: "shots",
                                            value,
                                        });
                                    }}
                                    value={paramsState.numberOfBalls}
                                    incrementValue={1}
                                    maxValue={60}
                                    minValue={1}
                                    showDecimal={false}
                                    showInfinity
                                />
                                <AccordionSlider
                                    min={1}
                                    max={60}
                                    marks={ballCountMarks}
                                    value={paramsState.numberOfBalls}
                                    onChange={(_, v) => {
                                        modifiedRef.current = true;
                                        paramsDispatch({
                                            type: "shots",
                                            value: v as number,
                                        });
                                    }}
                                    onChangeCommitted={(_, v) => {
                                        modifiedRef.current = true;
                                        paramsDispatch({
                                            type: "shots",
                                            value: v as number,
                                        });
                                    }}
                                    sx={{
                                        display: "table",
                                        margin: "0px auto 20px auto",
                                        maxWidth: "80%",
                                    }}
                                />
                            </Stack>
                        </NotchedOutline>
                    </Stack>
                </AccordionDetails>
            </Accordion>

            <PlayAppBar
                onPauseClicked={() => pause()}
                onPlayClicked={() => handlePlayClicked()}
                pauseDisabled={pauseDisabled}
                playDisabled={
                    visionUnavailable ||
                    playDisabled ||
                    selectedWorkout === null
                }
                playState={playState}
                showRecord
                onRecordClicked={() => captureVideo()}
                playSummary={summaryText}
                recordDisabled={visionUnavailable || captureDisabled}
                recordingStatus={captureStatus}
            />

            <CaptureToast captureStatus={captureStatus} />
        </Stack>
    );
}
