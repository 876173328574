import React from "react";
import { useNavigate } from "react-router-dom";

import CachedIcon from "@mui/icons-material/Cached";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

export interface FileControlsProps {
    editUrl?: string;
    resetDisabled: boolean;
    showDuplicate: boolean;
    initiateReset: () => void;
    initateCopy: () => void;
}

export default function FileControls({
    editUrl,
    resetDisabled,
    showDuplicate,
    initiateReset,
    initateCopy,
}: FileControlsProps): JSX.Element {
    const navigate = useNavigate();

    return (
        <Stack direction="row" spacing={3} justifyContent="center">
            {editUrl && (
                <Button
                    size="small"
                    startIcon={<EditIcon />}
                    onClick={() => navigate(editUrl)}
                >
                    Edit
                </Button>
            )}
            {showDuplicate && (
                <Button
                    size="small"
                    startIcon={<ContentCopyIcon />}
                    onClick={() => initateCopy()}
                >
                    Duplicate
                </Button>
            )}
            <Button
                size="small"
                startIcon={<CachedIcon />}
                onClick={() => initiateReset()}
                disabled={resetDisabled}
            >
                Reset
            </Button>
        </Stack>
    );
}

FileControls.defaultProps = {
    editUrl: undefined,
};
