import * as React from "react";

import Box from "@mui/material/Box";

import { capitalizeFirstLetter } from "../../../../util/text";
import { useLift } from "../../../hooks/useLift";
import {
    FreePlayKnownHeight,
    KnownHeightsAtom,
} from "../apps/freeplay/context";

import AccordionSlider from "./AccordionSlider";

export interface HeightProps {
    selectedHeight: number;
    onHeightChanged: (value: number) => void;
    disabled?: boolean;
}

function generateHeightMarks() {
    return Object.keys(KnownHeightsAtom).map((k) => {
        const value = KnownHeightsAtom[k as FreePlayKnownHeight];
        const label = capitalizeFirstLetter(k);

        return {
            value,
            label,
        };
    });
}

export default function Height({
    selectedHeight,
    onHeightChanged,
    disabled,
}: HeightProps): JSX.Element {
    const { liftRange } = useLift();
    const heightMarks = React.useMemo(() => generateHeightMarks(), []);

    return (
        <Box component="div" flexGrow={1}>
            <AccordionSlider
                disabled={disabled}
                min={liftRange.min}
                max={liftRange.max}
                marks={heightMarks}
                valueLabelDisplay="auto"
                valueLabelFormat={(v) => `${v}"`}
                value={selectedHeight}
                onChange={(_, v) => onHeightChanged(v as number)}
                sx={{
                    display: "table",
                    margin: "0px auto 20px auto",
                    maxWidth: "90%",
                    ".MuiSlider-track": {
                        color: "primary.light",
                    },
                    "& .MuiSlider-valueLabel": {
                        fontSize: "32px",
                    },
                }}
            />
        </Box>
    );
}
Height.defaultProps = { disabled: false };
