import * as React from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { round } from "../../../../util/positionUtil";
import { useSelectedSport } from "../../../common/context/sport";

import AccordionSlider from "./AccordionSlider";

export interface SpeedProps {
    selectedSpeed: number;
    onSpeedChanged: (value: number) => void;
    selectedSpeedVariation: number;
    onSpeedVariationChanged: (value: number) => void;
    disabled?: boolean;
}

const options: [string, number][] = [
    ["none", 0],
    ["slight", 1.5],
];

export default function Speed({
    selectedSpeed,
    onSpeedChanged,
    selectedSpeedVariation,
    onSpeedVariationChanged,
    disabled,
}: SpeedProps): JSX.Element {
    const {
        limits: { speed },
    } = useSelectedSport();

    const sliderMarks = React.useMemo(() => {
        const { minLaunchSpeed, maxLaunchSpeed } = speed;

        const marks: { label: string; value: number }[] = [];

        for (let i = minLaunchSpeed; i <= maxLaunchSpeed; i += 5) {
            marks.push({ label: i.toString(), value: i });
        }

        return marks;
    }, [speed]);

    const displaySpeed = round(selectedSpeed, 0.5);

    return (
        <Stack spacing={3}>
            <Box component="div" flexGrow={1}>
                <AccordionSlider
                    min={speed.minLaunchSpeed}
                    max={speed.maxLaunchSpeed}
                    marks={sliderMarks}
                    step={0.5}
                    value={displaySpeed}
                    valueLabelDisplay="auto"
                    valueLabelFormat={(v) => `${v}mph`}
                    onChange={(_, v) => onSpeedChanged(v as number)}
                    disabled={disabled}
                    sx={{
                        display: "table",
                        margin: "0px auto 20px auto",
                        maxWidth: "90%",
                        ".MuiSlider-track": {
                            color: "primary.light",
                        },
                        "& .MuiSlider-valueLabel": {
                            fontSize: "32px",
                        },
                    }}
                />
            </Box>
            <Stack spacing={1} direction="row">
                <Typography>Variance</Typography>
                <ButtonGroup>
                    {options.map(([label, value]) => {
                        const selected = value === selectedSpeedVariation;
                        return (
                            <Button
                                key={label}
                                disabled={disabled}
                                variant={selected ? "contained" : "outlined"}
                                sx={{
                                    color: selected
                                        ? "common.white"
                                        : "primary.light",
                                    backgroundColor: selected
                                        ? "primary.light"
                                        : "common.white",
                                    fontSize: "10px",
                                    "&:hover": {
                                        bgcolor: "primary.light",
                                    },
                                }}
                                onClick={() => {
                                    onSpeedVariationChanged(value);
                                }}
                            >
                                {label}
                            </Button>
                        );
                    })}
                </ButtonGroup>
            </Stack>
        </Stack>
    );
}
Speed.defaultProps = { disabled: false };
