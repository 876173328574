/* eslint-disable import/no-cycle */

import { getAuth } from "firebase/auth";

import logger from "../log";

type Method = "GET" | "POST" | "PUT" | "DELETE" | "PATCH" | "HEAD";

export class FetchError extends Error {
    statusCode: number;

    info: RequestInfo;

    method: Method;

    errorCode: string | null = null;

    constructor(
        code: number,
        method: Method,
        info: RequestInfo,
        message?: string,
        errorCode?: string,
    ) {
        super(message);
        this.statusCode = code;
        this.method = method;
        this.info = info;
        this.errorCode = errorCode ?? null;
    }
}

export class PairRequiredError extends Error {
    method: Method;

    info: RequestInfo;

    constructor(method: Method, info: RequestInfo, message?: string) {
        super(message);
        this.method = method;
        this.info = info;
    }
}

async function buildRequestInit(
    baseHeaders?: HeadersInit,
    method: Method = "GET",
    body?: unknown,
    timeout?: number,
): Promise<RequestInit> {
    const headers: HeadersInit = baseHeaders || {};

    const { currentUser } = getAuth();
    if (currentUser) {
        const token = await currentUser.getIdToken();
        (headers as Record<string, string>).authorization = `Bearer ${token}`;
    }

    const init: RequestInit = {
        method,
        headers,
    };
    if (body) {
        init.body = JSON.stringify(body);
    }

    if (timeout && timeout > 0 && AbortSignal?.timeout) {
        init.signal = AbortSignal.timeout(timeout);
    }

    return init;
}

export default async function fetchApi<T>(
    info: RequestInfo,
    method: Method = "GET",
    body?: unknown,
    timeout = 10_000,
): Promise<T> {
    const headers: HeadersInit = {
        accept: "application/json, text/plain, */*",
        "content-type": "application/json",
        "x-requested-with": "fetch",
    };

    const init = await buildRequestInit(headers, method, body, timeout);

    logger.debug(`FETCHING - [${init.method || "GET"}] ${info as string}`);

    const res = await fetch(info, init);

    logger.debug(
        `[${init.method || "GET"}] ${info as string}: ${res.status} - [${res.statusText}] - ${res.ok ? "ok" : "failed"}`,
    );

    if (res.headers.get("content-type")?.includes("application/json")) {
        const resJson = (await res.json()) as unknown;
        if (!res.ok) {
            const err = resJson as { message: string; code?: string };
            throw new FetchError(
                res.status,
                method,
                info,
                err.message,
                err.code,
            );
        }
        return resJson as T;
    }

    if (!res.ok) {
        throw new FetchError(
            res.status,
            method,
            info,
            `HTTP fetch error: ${res.status}: ${await res.text()}`,
        );
    }

    return null as T;
}

export async function fetchBlob(
    info: RequestInfo,
    method: Method = "GET",
    body?: unknown,
): Promise<Blob> {
    const headers: HeadersInit = {
        accept: "*/*",
        "content-type": "application/json",
        "x-requested-with": "fetch",
    };

    const init = await buildRequestInit(headers, method, body);

    const res = await fetch(info, init);

    if (!res.ok) {
        throw new FetchError(res.status, method, info, "Unknown error");
    }

    return res.blob();
}

export async function pairedFetchApi<T>(
    trainerId: number | undefined,
    apiUrl: string,
    method: Method = "GET",
    body?: unknown,
    timeout = 10_000,
): Promise<T> {
    if (!trainerId) {
        throw new PairRequiredError(method, apiUrl, "No trainer id provided");
    }

    const baseUrl = `/trainer/${trainerId}`;
    const fullUrl = `${baseUrl}${apiUrl.startsWith("/") ? "" : "/"}${apiUrl}`;
    logger.info(`pairedFetchAPI: calling URL ${fullUrl}`);

    return fetchApi<T>(fullUrl, method, body, timeout);
}

export function logFetchError(e: unknown, message?: string) {
    let context = "";
    if (message) {
        context = `${message}:\r\n`;
    }
    if (e instanceof FetchError) {
        logger.error(
            `${context}${e.message}, status: ${e.statusCode}, [${e.method}] - ${JSON.stringify(e.info)}`,
        );
    } else if (e instanceof PairRequiredError) {
        logger.error(
            `${context}${e.message}, [${e.method}] - ${JSON.stringify(e.info)}`,
        );
    } else {
        logger.error(
            `${context}Unexpected error fetching: ${JSON.stringify(e, ["message", "type", "name"])}`,
        );
    }
}
