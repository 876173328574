import * as React from "react";

import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";

import type { PowerState } from "@volley/shared/coach-models";

import { logFetchError, pairedFetchApi } from "../../util/fetchApi";
import { useStatus } from "../hooks/status";
import { useLift } from "../hooks/useLift";

import useUnclosableDialog from "./common/useUnclosableDialog";

const LIFTABLE_POWER_STATES: PowerState[] = ["L0", "L2"];

type HeadLoweringReason =
    | "refill"
    | "unpair"
    | "shutdown"
    | "balljam"
    | "battery-replace";

interface LowerHeadDialogProps {
    onLowered: () => void;
    onStopped: () => void;
    reason: HeadLoweringReason;
    onError?: (errorMessage: string) => void;
    message?: string;
}
export default function LowerHeadDialog({
    onLowered,
    onError,
    onStopped,
    message,
    reason,
}: LowerHeadDialogProps): JSX.Element {
    useUnclosableDialog();
    const {
        height,
        isLifting,
        liftStatus,
        refillHeight,
        setHeight,
        lowerHead,
        liftRange,
        error,
    } = useLift();
    const { status } = useStatus();
    const [stopping, setStopping] = React.useState(false);

    const targetHeight = React.useMemo(
        () => (reason === "refill" ? refillHeight : liftRange.min),
        [reason, refillHeight, liftRange.min],
    );

    const percent = React.useMemo(() => {
        const full = Math.abs(liftRange.max - liftRange.min);
        const diff = Math.abs(height - targetHeight);
        const inv = full - diff;
        return Math.floor((inv / full) * 100);
    }, [liftRange.max, liftRange.min, height, targetHeight]);

    React.useEffect(() => {
        const powerState = status?.trainer.powerState;

        if (Math.abs(targetHeight - height) <= 1 && !isLifting) {
            onLowered();
        } else if (powerState && !LIFTABLE_POWER_STATES.includes(powerState)) {
            // TODO: when we shutdown due to estop or other hardware issue
            // we may not be in a liftable power state but want to continue with
            // shutdown
            onLowered();
        } else if (stopping && !isLifting) {
            // we've requested a stop and it's no longer lifting, bubble up
            onStopped();
        } else if (error && onError) {
            onError(error);
        } else if (!isLifting && liftStatus === "idle") {
            if (
                reason === "balljam" ||
                reason === "battery-replace" ||
                reason === "unpair"
            ) {
                void lowerHead();
            } else if (reason !== "shutdown") {
                void setHeight(targetHeight);
            }
        }
    }, [
        height,
        error,
        isLifting,
        liftStatus,
        status,
        stopping,
        targetHeight,
        reason,
        lowerHead,
        setHeight,
        onError,
        onLowered,
        onStopped,
    ]);

    const onRequestStop = React.useCallback(async () => {
        setStopping(true);
        try {
            await pairedFetchApi(status?.clientId, "/api/motors-stop", "POST");
        } catch (e) {
            logFetchError(e, "Failed to soft-stop from LowerHeadDialog");
        }
    }, [status?.clientId]);

    return (
        <>
            <DialogTitle variant="h4">Moving Trainer Arm</DialogTitle>
            <DialogContent dividers>
                <DialogContentText>{message}</DialogContentText>
                <DialogContentText>{error}</DialogContentText>
                <Box
                    sx={{
                        my: 2,
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <CircularProgress
                        variant="determinate"
                        value={percent}
                        size={80}
                        sx={{ color: "primary.light" }}
                    />
                    <Typography
                        sx={{
                            top: "50%",
                            left: "50%",
                            position: "absolute",
                            transform: "translate(-50%, -50%)",
                            fontSize: "16pt",
                        }}
                        color="primary.light"
                    >
                        {height}
                        <Typography component="span" sx={{ fontSize: "11pt" }}>
                            in.
                        </Typography>
                    </Typography>
                </Box>
                <Box>
                    <LoadingButton
                        variant="contained"
                        color="error"
                        fullWidth
                        loading={stopping}
                        onClick={onRequestStop}
                    >
                        Stop
                    </LoadingButton>
                </Box>
            </DialogContent>
        </>
    );
}

LowerHeadDialog.defaultProps = {
    message: "The trainer arm is moving to the lowest position.",
    onError: () => {},
};
