import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";

import CloseIcon from "@mui/icons-material/Close";
import TuneIcon from "@mui/icons-material/Tune";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import type { AppWorkoutWithPlayCountAndFavorites as AppWorkout } from "@volley/data/dist/types/app-workout";
import { ContentProvider } from "@volley/data/dist/types/content-provider";
import { AppParameters } from "@volley/shared/app-models";
import { PlayMode } from "@volley/shared/apps/app-common-models";
import {
    LeveledWorkoutConfig,
    LevelNumber,
    CuratedWorkoutState,
    CuratedWorkoutParameters,
    Levels,
} from "@volley/shared/apps/curated-workout-models";
import { JSONObject } from "@volley/shared/common-models";
import { Side } from "@volley/shared/http/side";

import fetchApi, { logFetchError } from "../../../../../util/fetchApi";
import { CoordSys } from "../../../../../util/position-types";
import Loading from "../../../../common/Loading";
import Label from "../../../../common/Visualizer/Label";
import LocalizeWorkoutVisualizer from "../../../../common/Visualizer/LocalizeWorkoutVisualizer";
import Overlay from "../../../../common/Visualizer/Overlay";
import { multiLevelToVisualizer } from "../../../../common/Visualizer/utils";
import { useSelectedSport } from "../../../../common/context/sport";
import { useCurrentUser } from "../../../../hooks/currentUser";
import { LiftModal, useLift } from "../../../../hooks/useLift";
import usePosition from "../../../../hooks/usePosition";
import { useTrainerFeatures } from "../../../../hooks/useTrainerFeatures";
import { sideKeys } from "../../../Position/util";
import { SMALLE_MAX_BALLS } from "../../Accordions/ParamsAccordion";
import CaptureToast from "../../Shared/CaptureToast";
import DetailsButton from "../../Shared/DetailsButton";
import FavoriteButton from "../../Shared/FavoriteButton";
import FeatureNotifier from "../../Shared/FeatureNotifier";
import ManualTrim from "../../Shared/ManualTrim";
import PlayAppBar from "../../Shared/PlayAppBar";
import PlayLevelSelector from "../../Shared/PlayLevelSelector";
import ResetConfirmationDialog from "../../Shared/ResetConfirmation";
import SideSelector from "../../Shared/SideSelector";
import ThrowCount from "../../Shared/ThrowCount";
import ThrowInterval from "../../Shared/ThrowInterval";
import WorkoutCopyConfirmation from "../../Shared/WorkoutCopyConfirmation";
import useAppWorkouts from "../../db";
import LocalWorkouts from "../../localWorkoutState";
import useAppWorkoutPlay from "../../useAppWorkoutPlay";
import { KnownFeatures, makeWorkoutCompatible } from "../../util";
import ErrorDialog from "../curated/play/ErrorDialog";

import FileControls from "./FileControls";

type MultiLevelPlayParams = CuratedWorkoutParameters & {
    selectedLevel: LevelNumber;
};

interface MultiLevelPlayScreenState {
    missingFeatures: KnownFeatures[];
    dirty: boolean;
    stopWorkout: boolean;
    showResetDialog: boolean;
    duplicateId: number | null;
    advanceExpanded: boolean;
    manualTrim: number;
    manualBoost: number;
    toCopy: AppWorkout | null;
    workout: AppWorkout | null;
    playParameters: MultiLevelPlayParams;
}

type Action =
    | { type: "setMissingFeatures"; missingFeatures: KnownFeatures[] }
    | { type: "setShowResetDialog"; showResetDialog: boolean }
    | { type: "setDuplicateId"; duplicateId: number | null }
    | { type: "setPlayMode"; playMode: PlayMode }
    | { type: "setSelectedLevel"; selectedLevel: LevelNumber }
    | { type: "setAdvanceExpanded"; advanceExpanded: boolean }
    | { type: "setUserInterval"; userInterval: number }
    | { type: "setUserThrowCount"; userThrowCount: number }
    | { type: "setManualTrim"; manualTrim: number }
    | { type: "setManualBoost"; manualBoost: number }
    | { type: "setToCopy"; toCopy: AppWorkout | null }
    | { type: "setFavorite"; favorite: boolean }
    | {
          type: "setWorkout";
          workout: AppWorkout | null;
          paramOverrides?: MultiLevelPlayParams;
      };

function reducer(
    state: MultiLevelPlayScreenState,
    action: Action,
): MultiLevelPlayScreenState {
    const updatedState = { ...state };
    switch (action.type) {
        case "setAdvanceExpanded":
            updatedState.advanceExpanded = action.advanceExpanded;
            break;
        case "setDuplicateId":
            updatedState.duplicateId = action.duplicateId;
            break;
        case "setMissingFeatures":
            updatedState.missingFeatures = action.missingFeatures;
            break;
        case "setShowResetDialog":
            updatedState.showResetDialog = action.showResetDialog;
            break;
        case "setPlayMode":
            updatedState.playParameters.playMode = action.playMode;
            updatedState.dirty = true;
            updatedState.stopWorkout = true;
            break;
        case "setSelectedLevel": {
            const { levels } = state.workout
                ?.config as unknown as LeveledWorkoutConfig;
            const level = levels[action.selectedLevel];
            updatedState.dirty = false;
            updatedState.stopWorkout = true;
            updatedState.playParameters = {
                initialDelay: 1,
                intervalOverride: level.interval ?? 3,
                numberOfBalls: level.shotCount ?? 60,
                playMode: state.playParameters.playMode,
                shuffle: level.randomize ?? false,
                selectedLevel: action.selectedLevel,
            };
            break;
        }
        case "setUserInterval":
            updatedState.dirty = true;
            updatedState.stopWorkout = true;
            updatedState.playParameters.intervalOverride = action.userInterval;
            break;
        case "setUserThrowCount":
            updatedState.dirty = true;
            updatedState.stopWorkout = true;
            updatedState.playParameters.numberOfBalls = action.userThrowCount;
            // TODO: update local params
            break;
        case "setManualTrim":
            updatedState.manualTrim = action.manualTrim;
            updatedState.dirty = true;
            updatedState.stopWorkout = true;
            break;
        case "setManualBoost":
            updatedState.manualBoost = action.manualBoost;
            updatedState.dirty = true;
            updatedState.stopWorkout = true;
            break;
        case "setToCopy":
            updatedState.toCopy = action.toCopy;
            break;
        case "setFavorite":
            if (updatedState.workout) {
                updatedState.workout._count.favorites = action.favorite ? 1 : 0;
            }
            break;
        case "setWorkout": {
            if (action.workout) {
                const { config } = action.workout;
                const { levels } = config as unknown as LeveledWorkoutConfig;
                const level = action.paramOverrides
                    ? (action.paramOverrides.selectedLevel ?? 1) // default to level 1 in case of bad cached data
                    : (Levels.find((l) => levels[l].shots.length > 0) ?? 1);
                updatedState.workout = action.workout;
                updatedState.dirty = action.paramOverrides !== undefined;
                updatedState.stopWorkout = false;
                updatedState.manualBoost = 0;
                updatedState.manualTrim = 0;
                updatedState.missingFeatures = [];
                updatedState.showResetDialog = false;
                if (action.paramOverrides) {
                    updatedState.playParameters = action.paramOverrides;
                    updatedState.playParameters.selectedLevel = level;
                } else {
                    updatedState.playParameters = {
                        intervalOverride: levels[level].interval ?? 3,
                        numberOfBalls: levels[level].shotCount ?? 60,
                        playMode: "standard",
                        initialDelay: 1,
                        shuffle: levels[level].randomize ?? false,
                        selectedLevel: level,
                    };
                }

                break;
            }
            return state;
        }
        default:
            break;
    }

    if (
        (updatedState.dirty && action.type !== "setWorkout") ||
        action.type === "setSelectedLevel"
    ) {
        LocalWorkouts.set({
            appId: updatedState.workout?.appId ?? 0,
            config: updatedState.workout?.config as unknown as JSONObject,
            id: updatedState.workout?.id ?? 0,
            height: updatedState.workout?.positionHeight,
            params: updatedState.playParameters as unknown as JSONObject,
        });
    }
    return updatedState;
}

const defaultState: MultiLevelPlayScreenState = {
    advanceExpanded: false,
    duplicateId: null,
    manualTrim: 0,
    manualBoost: 0,
    missingFeatures: [],
    dirty: false,
    stopWorkout: false,
    showResetDialog: false,
    toCopy: null,
    workout: null,
    playParameters: {
        initialDelay: 1,
        intervalOverride: 3,
        numberOfBalls: 60,
        playMode: "standard",
        shuffle: false,
        selectedLevel: 1,
    },
};

export default function MultiLevelPlayScreen(): JSX.Element {
    const { id } = useParams<{ id: string }>();
    const validId = parseInt(id ?? "", 10);
    const navigate = useNavigate();
    const features = useTrainerFeatures();
    const { loadMultiShot, getWorkout, copyToMyWorkouts, loading } =
        useAppWorkouts();
    const { currentUser } = useCurrentUser();
    const { selected: selectedSport } = useSelectedSport();

    const [hasLocalized, setHasLocalized] = React.useState(false);
    const [state, dispatch] = React.useReducer(reducer, defaultState);
    const [providerName, setProviderName] = React.useState<string | null>(null);

    const workoutForVisualizer = React.useMemo(() => {
        if (state.workout && state.playParameters.selectedLevel) {
            return multiLevelToVisualizer(
                state.workout,
                state.playParameters.selectedLevel,
                state.playParameters.playMode,
                selectedSport,
            );
        }

        return undefined;
    }, [
        state.workout,
        state.playParameters.selectedLevel,
        state.playParameters.playMode,
        selectedSport,
    ]);

    const {
        error: localizationError,
        improve,
        position: localizedPosition,
    } = usePosition();

    const workoutPosition = React.useMemo(() => {
        // localized position can be a cached value from a previous localization attempt
        // if we pass this along to the useAppWorkoutPlay hook, it can result in unintentional trim application
        // If the selected sport is not platform tennis, or the user hasn't localized for this workout yet,
        // or if localization reports errors or need for improvement... don't use the localized position value
        if (
            selectedSport !== "PLATFORM_TENNIS" ||
            !hasLocalized ||
            improve ||
            localizationError
        ) {
            return undefined;
        }

        return localizedPosition;
    }, [
        selectedSport,
        localizationError,
        improve,
        localizedPosition,
        hasLocalized,
    ]);

    const { stop: stopLift } = useLift();

    const {
        pause,
        start,
        stop,
        pauseDisabled,
        playDisabled,
        playState,
        playInitiated,
        workoutState,
        captureVideo,
        captureDisabled,
        captureStatus,
    } = useAppWorkoutPlay({
        workout: state.workout,
        parameters: state.playParameters as unknown as AppParameters,
        localizedPosition: workoutPosition,
        level: state.playParameters.selectedLevel,
    });
    const curatedWorkoutState =
        workoutState as unknown as CuratedWorkoutState | null;
    const ballsThrown = curatedWorkoutState?.currentShotNumber ?? 0;
    const totalBalls = React.useMemo(
        () =>
            state.playParameters.numberOfBalls === SMALLE_MAX_BALLS
                ? "All"
                : state.playParameters.numberOfBalls,
        [state.playParameters.numberOfBalls],
    );
    const shotSummary = playInitiated ? `${ballsThrown} of ${totalBalls}` : "";

    const fetchWorkout = React.useCallback(async () => {
        if (!validId) return;

        const loaded = await loadMultiShot(validId);
        if (loaded) {
            const { workout: data, params, source } = loaded;
            dispatch({
                type: "setWorkout",
                workout: data,
                paramOverrides:
                    source === "original"
                        ? undefined
                        : (params as unknown as MultiLevelPlayParams),
            });
        }
    }, [loadMultiShot, validId]);

    React.useEffect(() => {
        fetchWorkout().catch((e) =>
            logFetchError(e, "Failed to fetch workout."),
        );
    }, [fetchWorkout]);

    const fetchProvider = React.useCallback(async () => {
        if (!state.workout || !state.workout.contentProviderId) {
            setProviderName(null);
            return;
        }

        const provider = await fetchApi<ContentProvider>(
            `/api/content-providers/${state.workout.contentProviderId}`,
        );
        if (provider.name) {
            setProviderName(provider.name);
        }
    }, [state.workout]);

    React.useEffect(() => {
        fetchProvider().catch((e) =>
            logFetchError(e, "Failed to fetch workout provider information"),
        );
    }, [fetchProvider]);

    const resetToDefault = React.useCallback(async () => {
        if (playState !== "stopped") {
            void stop();
        }
        const original = await getWorkout(validId);
        if (original) {
            dispatch({
                type: "setWorkout",
                workout: original,
            });
            LocalWorkouts.clear();
        } else {
            // FIXME: Notify the user somehow?
        }
    }, [getWorkout, validId, playState, stop]);

    // TODO: Create single validator function
    React.useEffect(() => {
        // if features array is empty, it is not yet initialized
        // if the workout is null, it hasn't been loaded
        // in either case, we can't run the compatibility check yet
        if (state.workout === null || features.length === 0) {
            return;
        }

        const { missing, workout: updated } = makeWorkoutCompatible(
            state.workout,
            features,
        );
        if (missing.length > 0) {
            dispatch({
                type: "setWorkout",
                workout: updated,
            });
            dispatch({ type: "setMissingFeatures", missingFeatures: missing });
            LocalWorkouts.clear();
        }
    }, [state.workout, features]);

    const handleLiftStop = React.useCallback(async () => {
        await stopLift();
        await stop();
    }, [stopLift, stop]);

    const editUrl = React.useMemo(() => {
        if (state.workout === null) {
            return undefined;
        }

        const createdWorkout =
            state.workout.createdBy === currentUser?.email &&
            state.workout.appId !== 7;

        if (createdWorkout) {
            return `../edit/${state.workout.appId}/${state.workout.id}`;
        }

        return undefined;
    }, [state.workout, currentUser]);

    const playerPositionForSide = React.useMemo(() => {
        if (state.workout) {
            const { playerPosition } = state.workout
                .config as unknown as LeveledWorkoutConfig;
            return {
                x: playerPosition?.x ?? 0,
                y: playerPosition?.y ?? 0,
                sys: (selectedSport === "PLATFORM_TENNIS"
                    ? "court"
                    : "physics") as CoordSys,
            };
        }

        return {
            x: 0,
            y: 0,
            sys: "physics" as CoordSys,
        };
    }, [state.workout, selectedSport]);

    const side = React.useMemo(() => {
        if (state.playParameters.playMode === "dual") {
            return "both";
        }

        const { deuceKey } = sideKeys(playerPositionForSide, selectedSport);
        if (deuceKey === state.playParameters.playMode) {
            return "deuce";
        }
        return "ad";
    }, [state.playParameters.playMode, playerPositionForSide, selectedSport]);

    if (state.workout === null) {
        if (loading) {
            return <Loading />;
        }

        return (
            <ErrorDialog
                buttonText="Select Another"
                header="Problem Loading this Workout"
                text="Please select another workout."
                onClick={() => navigate("/", { replace: true })}
            />
        );
    }

    return (
        <Stack
            spacing={0.5}
            px={1}
            sx={{
                backgroundColor: (t) => t.palette.background.paper,
                height: "calc(100vh - 64px)",
            }}
        >
            <LocalizeWorkoutVisualizer
                hasLocalized={hasLocalized}
                setHasLocalized={() => setHasLocalized(true)}
                playMode={state.playParameters.playMode}
                workout={workoutForVisualizer}
                maxHeight={225}
                overlay={
                    <Overlay>
                        <FavoriteButton
                            workoutId={state.workout.id}
                            isFavorite={state.workout._count?.favorites > 0}
                            onClick={() => {
                                if (state.workout) {
                                    const isFavorite =
                                        state.workout._count.favorites > 0;
                                    dispatch({
                                        type: "setFavorite",
                                        favorite: !isFavorite,
                                    });
                                }
                            }}
                        />
                        <Label text={state.workout.name} />
                        <DetailsButton workout={state.workout} />
                    </Overlay>
                }
                side={side as Side | "both"}
            />
            <Collapse in={state.advanceExpanded}>
                <FileControls
                    editUrl={editUrl}
                    resetDisabled={!state.dirty}
                    showDuplicate={providerName === "VOLLEY"}
                    initiateReset={resetToDefault}
                    initateCopy={() =>
                        dispatch({ type: "setToCopy", toCopy: state.workout })
                    }
                />
            </Collapse>
            <Stack
                spacing={2.5}
                sx={{
                    maxHeight: "calc(100svh - 320px)",
                    overscrollBehavior: "contain",
                    overflowY: "auto",
                }}
            >
                <SideSelector
                    disabled={playState === "playing"}
                    playerPosition={playerPositionForSide}
                    playMode={state.playParameters.playMode}
                    setPlayMode={(p) => {
                        dispatch({ type: "setPlayMode", playMode: p });
                    }}
                    workoutX={state.workout.positionX}
                />
                <PlayLevelSelector
                    disabled={playState === "playing"}
                    selectedLevel={state.playParameters.selectedLevel}
                    setSelectedLevel={(l) => {
                        dispatch({
                            type: "setSelectedLevel",
                            selectedLevel: l,
                        });
                    }}
                    workout={state.workout}
                />
                {!state.advanceExpanded && (
                    <Box component="div">
                        <Typography
                            variant="caption"
                            textAlign="center"
                            width="100%"
                            component="div"
                        >
                            {`${state.playParameters.intervalOverride} sec. rate, ${state.playParameters.numberOfBalls === SMALLE_MAX_BALLS ? "all" : state.playParameters.numberOfBalls} balls`}
                        </Typography>
                        <Button
                            fullWidth
                            size="small"
                            endIcon={<TuneIcon />}
                            disabled={playState === "playing"}
                            onClick={() =>
                                dispatch({
                                    type: "setAdvanceExpanded",
                                    advanceExpanded: true,
                                })
                            }
                            sx={{
                                marginTop: "0px",
                            }}
                        >
                            Advanced
                        </Button>
                    </Box>
                )}
                <Collapse in={state.advanceExpanded}>
                    <Stack
                        spacing={4}
                        sx={{
                            marginBottom: "120px",
                        }}
                    >
                        <ThrowInterval
                            disabled={playState === "playing"}
                            selectedThrowInterval={
                                state.playParameters.intervalOverride
                            }
                            onUserThrowIntervalChanged={(i) => {
                                dispatch({
                                    type: "setUserInterval",
                                    userInterval: i,
                                });
                            }}
                        />
                        <ThrowCount
                            disabled={playState === "playing"}
                            selectedThrowCount={
                                state.playParameters.numberOfBalls
                            }
                            onUserThrowCountChanged={(c) => {
                                dispatch({
                                    type: "setUserThrowCount",
                                    userThrowCount: c,
                                });
                            }}
                        />
                        <ManualTrim
                            disabled={playState === "playing"}
                            manualTrim={state.manualTrim}
                            setManualTrim={(t) => {
                                dispatch({
                                    type: "setManualTrim",
                                    manualTrim: t,
                                });
                            }}
                        />
                    </Stack>
                </Collapse>
            </Stack>
            <PlayAppBar
                onPlayClicked={async () => {
                    if (state.stopWorkout && playState !== "stopped") {
                        await stop();
                    }
                    await start(state.manualTrim, state.manualBoost);
                }}
                onPauseClicked={() => pause()}
                pauseDisabled={pauseDisabled}
                playDisabled={playDisabled}
                showRecord
                onRecordClicked={() => captureVideo()}
                playState={playState}
                playSummary={shotSummary}
                recordDisabled={captureDisabled}
                recordingStatus={captureStatus}
            />
            <LiftModal
                stop={handleLiftStop}
                targetHeight={
                    playInitiated ? state.workout.positionHeight : undefined
                }
                message="The trainer is adjusting the head height."
            />
            <CaptureToast captureStatus={captureStatus} />
            <FeatureNotifier missing={state.missingFeatures} />
            <ResetConfirmationDialog
                open={state.showResetDialog}
                onCancel={() =>
                    dispatch({
                        type: "setShowResetDialog",
                        showResetDialog: false,
                    })
                }
                onConfirm={() => resetToDefault()}
            />
            <WorkoutCopyConfirmation
                onCancel={() => dispatch({ type: "setToCopy", toCopy: null })}
                onConfirm={async () => {
                    if (state.toCopy) {
                        const copyId = await copyToMyWorkouts(state.toCopy);
                        if (copyId) {
                            dispatch({
                                type: "setDuplicateId",
                                duplicateId: copyId,
                            });
                        }
                    }
                    dispatch({ type: "setToCopy", toCopy: null });
                }}
                workout={state.toCopy}
            />
            <Snackbar
                open={state.duplicateId !== null}
                onClose={() =>
                    dispatch({ type: "setDuplicateId", duplicateId: null })
                }
                autoHideDuration={5000}
            >
                <Alert
                    variant="filled"
                    severity="info"
                    action={
                        <IconButton
                            onClick={() =>
                                dispatch({
                                    type: "setDuplicateId",
                                    duplicateId: null,
                                })
                            }
                            color="inherit"
                        >
                            <CloseIcon />
                        </IconButton>
                    }
                >
                    <AlertTitle>Workout Copied to My Workouts</AlertTitle>
                    {editUrl && (
                        <Stack direction="row" justifyContent="flex-end">
                            <Button
                                size="small"
                                color="inherit"
                                onClick={() => navigate(editUrl)}
                            >
                                View
                            </Button>
                        </Stack>
                    )}
                </Alert>
            </Snackbar>
        </Stack>
    );
}
