import * as React from "react";
import { Link as RouterLink } from "react-router-dom";

import TuneIcon from "@mui/icons-material/Tune";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import type { AppWorkoutWithRelations } from "@volley/data/dist/types/app-workout";
import { ModuleConfig } from "@volley/shared/apps/module-models";

import { getEnv } from "../../../../../../util";
import { useCurrentUser } from "../../../../../hooks/currentUser";

const bucket = () => {
    const env = getEnv();
    if (env === "production") return "volley-production-media";
    if (env === "rc") return "volley-rc-media";
    return "volley-staging-media";
};

const cdnUrl = (slug: string) => `/cdn/gcs/${bucket()}/modules/${slug}.mp4`;
const thumbnailUrl = (slug: string) =>
    `/cdn/gcs/${bucket()}/modules/${slug}-720.jpg`;

interface Props {
    workout: AppWorkoutWithRelations;
}

export default function ModuleOverview({ workout }: Props): JSX.Element {
    const { currentUser } = useCurrentUser();
    const config = workout.config as unknown as ModuleConfig;

    return (
        <Stack spacing={2}>
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <video
                controls
                playsInline
                style={{
                    maxWidth: "100%",
                    aspectRatio: "16 / 9",
                }}
                poster={thumbnailUrl(config.videoSlug)}
            >
                <source src={cdnUrl(config.videoSlug)} type="video/mp4" />
            </video>
            <Stack spacing={2} px={2}>
                <Typography variant="h3" component="h1">
                    {workout.name}
                </Typography>
                <Typography sx={{ whiteSpace: "pre-line" }}>
                    {config.details}
                </Typography>
                <Button
                    color="secondary"
                    fullWidth
                    variant="contained"
                    component={RouterLink}
                    to="start"
                >
                    Start
                </Button>
                {workout.createdBy === currentUser?.email && (
                    <Button
                        fullWidth
                        startIcon={<TuneIcon />}
                        component={RouterLink}
                        to={`../edit/${workout.appId}/${workout.id}`}
                    >
                        Edit
                    </Button>
                )}
            </Stack>
        </Stack>
    );
}
