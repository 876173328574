import * as React from "react";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Slider from "@mui/material/Slider";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { Trainer } from "@volley/data/dist/types/trainer";

import logger from "../../log";
import theme from "../../theme";
import fetchApi from "../../util/fetchApi";
import CloseableDialogTitle from "../common/CloseableDialogTitle";
import { useStatus } from "../hooks/status";

import useDialog from "./useDialog";

interface SignalStrengthIndicatorProps {
    wifiMetrics: WifiMetrics;
}

const MIN = -80;
const MAX = -50;
// normalize the signal strength to fit between 0 and 100
const normalize = (value: number): number => {
    const normalized = ((value - MIN) * 100) / (MAX - MIN);

    // show a little bit of red if the signal is really bad
    if (normalized < 2) {
        return 2;
    }

    return normalized;
};

function SignalStrengthIndicator({
    wifiMetrics,
}: SignalStrengthIndicatorProps): JSX.Element {
    const { signalStrength, ping } = wifiMetrics;
    let color;
    let description;
    if (signalStrength <= -70) {
        color = theme.palette.error.main;
        description = "poor";
    } else if (signalStrength <= -60) {
        color = theme.palette.warning.main;
        description = "fair";
    } else {
        color = theme.palette.success.main;
        description = "good";
    }

    return (
        <Box component="div" sx={{ px: 1 }}>
            <Typography variant="body2" sx={{ mt: 2 }}>
                The trainer&apos;s Wi-Fi signal strength is {description}.
            </Typography>

            <Slider
                value={normalize(signalStrength)}
                min={0}
                max={100}
                step={null}
                marks={[
                    { value: 5, label: "Poor" },
                    { value: normalize(-70), label: "Fair" },
                    { value: normalize(-60), label: "Good" },
                    { value: 95, label: "Great" },
                ]}
                disabled
                sx={{
                    "& .MuiSlider-thumb": {
                        display: "none",
                    },
                    "& .MuiSlider-rail": {
                        opacity: 0.5,
                    },
                    "& .MuiSlider-track": {
                        backgroundColor: color,
                    },
                }}
            />

            <Typography variant="body2" sx={{ mt: 2 }}>
                Connected network: <strong>{wifiMetrics.essid}</strong>
            </Typography>

            <Box component="div" sx={{ mt: 1 }}>
                <Typography variant="caption" component="p">
                    Signal Strength: {Math.round(signalStrength)} dBm
                </Typography>
                <Typography variant="caption" component="p">
                    Ping: {ping} ms
                </Typography>
            </Box>
        </Box>
    );
}
interface WifiCredentials {
    ssid: string;
    passphrase: string;
}

interface WifiMetrics {
    signalStrength: number;
    ping: number;
    essid: string;
}

export default function WifiCredentialsDialog(): JSX.Element {
    const [ssid, setSsid] = React.useState("");
    const [passphrase, setPassphrase] = React.useState("");
    const [showPassword, setShowPassword] = React.useState(false);
    const [submitting, setSubmitting] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [resetting, setResetting] = React.useState(false);
    const [supported, setSupported] = React.useState(true);
    const [error, setError] = React.useState("");

    const { status } = useStatus();
    const { setDialogType } = useDialog();

    React.useEffect(() => {
        async function loadWifiCredentials() {
            try {
                if (status?.clientId === undefined) {
                    throw new Error("No trainer connected");
                }

                // we need to fetch the trainer for the trainer id
                const trainerResult = await fetchApi<Trainer>(
                    `/api/trainers/clientId_${status?.clientId}`,
                );
                if (!trainerResult) {
                    throw new Error("Trainer not found");
                }

                const result = await fetchApi<WifiCredentials>(
                    `/api/trainers/${trainerResult.id}/wifi-credentials`,
                );
                setSsid(result.ssid);
                setPassphrase(result.passphrase);
            } catch (err: unknown) {
                if (
                    err instanceof Error &&
                    err.message === "Method not found"
                ) {
                    setSupported(false);
                } else {
                    logger.error("Error getting wifi credentials", {
                        error: err,
                    });
                    setError((err as Error).message);
                }
            } finally {
                setLoading(false);
            }
        }

        void loadWifiCredentials();
    }, [status?.clientId]);

    const onSubmit = React.useCallback(
        async (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();

            setSubmitting(true);
            try {
                if (status?.clientId === undefined) {
                    throw new Error("No trainer connected");
                }

                const trainerResult = await fetchApi<Trainer>(
                    `/api/trainers/clientId_${status?.clientId}`,
                );
                if (!trainerResult) {
                    throw new Error("Trainer not found");
                }

                await fetchApi(
                    `/api/trainers/${trainerResult.id}/wifi-credentials`,
                    "POST",
                    { ssid, passphrase },
                );

                setDialogType("Device");
            } catch (err: unknown) {
                logger.error("Error setting wifi credentials", { error: err });
                setError((err as Error).message);
            } finally {
                setSubmitting(false);
            }
        },
        [status?.clientId, ssid, passphrase, setDialogType],
    );

    const onReset = React.useCallback(
        async (e: React.FormEvent<HTMLButtonElement>) => {
            e.preventDefault();

            if (
                !window.confirm(
                    "Are you sure you want to reset this trainer's wifi credentials?",
                )
            ) {
                return;
            }

            setResetting(true);
            try {
                if (status?.clientId === undefined) {
                    throw new Error("No trainer connected");
                }

                const trainerResult = await fetchApi<Trainer>(
                    `/api/trainers/clientId_${status?.clientId}`,
                );
                if (!trainerResult) {
                    throw new Error("Trainer not found");
                }

                await fetchApi(
                    `/api/trainers/${trainerResult.id}/wifi-credentials`,
                    "DELETE",
                );

                setSsid("");
                setPassphrase("");
            } catch (err: unknown) {
                logger.error("Error resetting wifi credentials", {
                    error: err,
                });
                setError((err as Error).message);
            } finally {
                setResetting(false);
            }
        },
        [status?.clientId],
    );

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const wifiMetrics: WifiMetrics | undefined = React.useMemo(() => {
        // if we're not connected we don't have metrics
        if (!status?.system.wifi?.essid) {
            return undefined;
        }

        return {
            signalStrength: status.system.wifi.signal,
            ping: status.system.wifi.monitorPing ?? 0,
            essid: status.system.wifi.essid,
        };
    }, [status?.system.wifi]);

    return (
        <>
            <CloseableDialogTitle onClose={() => setDialogType("Device")}>
                Trainer Wi-Fi
            </CloseableDialogTitle>
            <DialogContent dividers>
                <Stack spacing={2} alignContent="start">
                    {!supported && (
                        <Typography>
                            Wi-Fi configuration is not supported by this
                            trainer.
                        </Typography>
                    )}
                    {error && <Typography color="error">{error}</Typography>}
                    {!loading && supported && (
                        <form onSubmit={onSubmit} autoComplete="off">
                            <Stack spacing={2}>
                                <TextField
                                    fullWidth
                                    label="SSID"
                                    variant="outlined"
                                    value={ssid}
                                    size="small"
                                    sx={{ pr: 2 }}
                                    onChange={(e) => setSsid(e.target.value)}
                                />
                                <TextField
                                    fullWidth
                                    label="Password"
                                    variant="outlined"
                                    value={passphrase}
                                    size="small"
                                    sx={{ pr: 2 }}
                                    type={showPassword ? "text" : "password"}
                                    onChange={(e) =>
                                        setPassphrase(e.target.value)
                                    }
                                    slotProps={{
                                        input: {
                                            endAdornment: (
                                                <IconButton
                                                    onClick={toggleShowPassword}
                                                >
                                                    {showPassword ? (
                                                        <VisibilityOffIcon />
                                                    ) : (
                                                        <VisibilityIcon />
                                                    )}
                                                </IconButton>
                                            ),
                                        },
                                    }}
                                />
                                <Box component="div">
                                    <LoadingButton
                                        variant="contained"
                                        type="submit"
                                        loading={submitting}
                                        color="secondary"
                                    >
                                        Submit
                                    </LoadingButton>
                                    <LoadingButton
                                        variant="contained"
                                        type="button"
                                        loading={resetting}
                                        color="info"
                                        sx={{ ml: 2 }}
                                        onClick={onReset}
                                    >
                                        Reset
                                    </LoadingButton>
                                </Box>
                            </Stack>
                        </form>
                    )}
                </Stack>
            </DialogContent>
            {wifiMetrics && (
                <>
                    <DialogTitle>Strength</DialogTitle>
                    <DialogContent dividers>
                        <SignalStrengthIndicator wifiMetrics={wifiMetrics} />
                    </DialogContent>
                </>
            )}
        </>
    );
}
