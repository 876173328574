"use strict";
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable no-multi-spaces */
/* ______________________________________________________________________________
  Physics models  Data types used for modeling the trainer, ball launch/flight
  All geometric quantities are in 'physics coordinates' unless
  otherwise noted.
  See Also: Coordinate Systems and Units (in conversions.ts)
  ______________________________________________________________________________
*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefaultSimulationParams = exports.DefaultPhysicalConstants = exports.CourtGeometryBySport = exports.BallParamsBySport = exports.CourtSurface = exports.ValidPhysicsModelNames = exports.isSport = exports.Sports = exports.metersPerInch = exports.metersPerFoot = void 0;
// Length conversions (convenience)
exports.metersPerFoot = 0.3048;
exports.metersPerInch = 0.0254;
/**
 * Sports
 */
exports.Sports = ["PLATFORM_TENNIS", "PADEL", "TENNIS", "PICKLEBALL"];
function isSport(sport) {
    return exports.Sports.includes(sport);
}
exports.isSport = isSport;
/**
 * Physics Models
 */
exports.ValidPhysicsModelNames = [
    "physics-20B-base",
    "physics-20C-base",
    "physics-20D-base",
    "physics-30A-base",
];
/**
 * CourtSurface
 * The surfaces the ball can hit.
 */
var CourtSurface;
(function (CourtSurface) {
    CourtSurface["Court"] = "Court";
    CourtSurface["LeftWall"] = "LeftWall";
    CourtSurface["RightWall"] = "RightWall";
    CourtSurface["BackWall"] = "BackWall";
    CourtSurface["Net"] = "Net";
})(CourtSurface = exports.CourtSurface || (exports.CourtSurface = {}));
exports.BallParamsBySport = {
    PLATFORM_TENNIS: {
        diameter: 2.5 * 0.0254,
        mass: 0.072,
        drag: 0.85,
    },
    PADEL: {
        diameter: 6.7 * 0.01,
        mass: 57.7 * 0.001,
        drag: 0.637,
    },
    TENNIS: {
        diameter: 6.7 * 0.01,
        mass: 57.7 * 0.001,
        drag: 0.637,
    },
    PICKLEBALL: {
        diameter: 7.425 * 0.01,
        mass: 24.3 * 0.001,
        drag: 0.40,
    },
};
/**
 * CourtGeometryBySport
 * Select sport-specific court geometry
 */
exports.CourtGeometryBySport = {
    PLATFORM_TENNIS: {
        PLATFORM_WIDTH: 30.0 * exports.metersPerFoot,
        PLATFORM_LENGTH: 60.0 * exports.metersPerFoot,
        COURT_WIDTH: 20.0 * exports.metersPerFoot,
        COURT_LENGTH: 44.0 * exports.metersPerFoot,
        SERVICE_WIDTH: 16.0 * exports.metersPerFoot,
        SERVICE_LENGTH: 24.0 * exports.metersPerFoot,
        NET_WIDTH: 26.0 * exports.metersPerFoot,
        NET_HEIGHT_POST: 3.083 * exports.metersPerFoot,
        NET_HEIGHT_CENTER: 2.833 * exports.metersPerFoot,
        WALL_HEIGHT: 12 * exports.metersPerFoot,
        TALL_WALL_HEIGHT: 0,
        TALL_WALL_SIDE_LENGTH: 0,
    },
    PADEL: {
        PLATFORM_WIDTH: 10.0,
        PLATFORM_LENGTH: 20.0,
        COURT_WIDTH: 10.0,
        COURT_LENGTH: 20.0,
        SERVICE_WIDTH: 10.0,
        SERVICE_LENGTH: 14.0,
        NET_WIDTH: 10.0,
        NET_HEIGHT_POST: 0.92,
        NET_HEIGHT_CENTER: 0.88,
        WALL_HEIGHT: 3.0,
        TALL_WALL_HEIGHT: 4.0,
        TALL_WALL_SIDE_LENGTH: 2.0,
    },
    TENNIS: {
        PLATFORM_WIDTH: 60.0 * exports.metersPerFoot,
        PLATFORM_LENGTH: 120.0 * exports.metersPerFoot,
        COURT_WIDTH: 36.0 * exports.metersPerFoot,
        COURT_LENGTH: 78.0 * exports.metersPerFoot,
        SERVICE_WIDTH: 27.0 * exports.metersPerFoot,
        SERVICE_LENGTH: 42.0 * exports.metersPerFoot,
        NET_WIDTH: 42.0 * exports.metersPerFoot,
        NET_HEIGHT_POST: 3.51 * exports.metersPerFoot,
        NET_HEIGHT_CENTER: 3.0 * exports.metersPerFoot,
        WALL_HEIGHT: 0,
        TALL_WALL_HEIGHT: 0,
        TALL_WALL_SIDE_LENGTH: 0,
    },
    PICKLEBALL: {
        PLATFORM_WIDTH: 30.0 * exports.metersPerFoot,
        PLATFORM_LENGTH: 60.0 * exports.metersPerFoot,
        COURT_WIDTH: 20.0 * exports.metersPerFoot,
        COURT_LENGTH: 44.0 * exports.metersPerFoot,
        SERVICE_WIDTH: 20.0 * exports.metersPerFoot,
        // SERVICE_LENGTH = KITCHEN FOR PICKLEBALL
        SERVICE_LENGTH: 14.0 * exports.metersPerFoot,
        NET_WIDTH: 22.0 * exports.metersPerFoot,
        NET_HEIGHT_POST: 3.0 * exports.metersPerFoot,
        NET_HEIGHT_CENTER: (34.0 / 12.0) * exports.metersPerFoot,
        WALL_HEIGHT: 0,
        TALL_WALL_HEIGHT: 0,
        TALL_WALL_SIDE_LENGTH: 0,
    },
};
/**
 * DefaultPhysicalConstants
 * Default values for the physical constants required for simulation.
 */
exports.DefaultPhysicalConstants = {
    gravity: 9.8,
    airDensity: 1.225,
    wallRestCoeff: 0.5,
    courtRestCoeff: 0.8,
    netRestCoeff: 0.01,
};
/**
 * DefaultSimulationParams
 * Default values for parameters that control ball flight simulation.
 */
exports.DefaultSimulationParams = {
    timeStep: 0.001,
    duration: 5.0,
    bounces: 3,
    wallBounceEnabled: true,
    maxSteps: 50,
    stepDecay: 0.6,
    stopDist: 0.001,
};
