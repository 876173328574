import * as React from "react";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import DeleteIcon from "@mui/icons-material/Delete";
import DetailsIcon from "@mui/icons-material/Details";
import DoneIcon from "@mui/icons-material/Done";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SpeedIcon from "@mui/icons-material/Speed";
import SportsTennisIcon from "@mui/icons-material/SportsTennis";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import ThreeSixtyIcon from "@mui/icons-material/ThreeSixty";
import TimerIcon from "@mui/icons-material/Timer";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import { convert } from "@volley/physics";
import type { PlayMode } from "@volley/shared/apps/app-common-models";
import type { ModuleShot } from "@volley/shared/apps/module-models";

import logger from "../../../../../../../log";
import { logFetchError } from "../../../../../../../util/fetchApi";
import type { TrainerPositionLike } from "../../../../../../../util/position-types";
import { usePairingContext } from "../../../../../../hooks/pairingStatus";
import { mph2mps, mps2mph } from "../../../../../util/conversions";
import useThrowNow from "../../../../../util/useThrowNow";
import Aim from "../../../../Shared/Aim";
import Pad from "../../../../Shared/Pad";
import Speed from "../../../../Shared/Speed";
import Spin from "../../../../Shared/Spin";

import ShotDetails from "./ShotDetails";

type TabValue = "details" | "speed" | "spin" | "aim" | "arc" | "pad";

interface Props {
    shot: ModuleShot;
    playMode: PlayMode;
    position: TrainerPositionLike;
    interval: number;
    updateShot: <K extends keyof ModuleShot>(
        property: K,
        value: ModuleShot[K],
    ) => void;
    onSave: () => void;
    onDuplicate: () => void;
    onCancel: () => void;
    onBack?: () => void;
    onNext?: () => void;
}

export default function ShotControls({
    shot,
    playMode,
    position,
    interval,
    updateShot,
    onSave,
    onDuplicate,
    onCancel,
    onBack,
    onNext,
}: Props): JSX.Element {
    const { status: pairingStatus } = usePairingContext();
    const { canThrow, launchData, throwing, makeShot } = useThrowNow({
        launchSpeed: shot.launchSpeed,
        pan: playMode === "mirror" ? shot.pan * -1 : shot.pan,
        tilt: shot.tilt,
        spinAxis: shot.spinDirection,
        spinLevel: shot.spinLevel,
        height: position.heightIn,
        plannedPosition: position,
    });
    const [tab, setTab] = React.useState<TabValue>("details");
    const [moreAnchorEl, setMoreAnchorEl] = React.useState<HTMLElement | null>(
        null,
    );

    const onMoreOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
        setMoreAnchorEl(e.currentTarget);
    };

    const onMoreClose = () => {
        setMoreAnchorEl(null);
    };

    const onMoreClick = (cb: () => void) => {
        cb();
        onMoreClose();
    };

    const maxSpinLevel = React.useMemo(() => {
        let level = 0;
        try {
            level = convert.maxspinlevel(shot.spinDirection, shot.launchSpeed);
        } catch (e) {
            logger.error(
                `Failed to get max spin level for axis: ${shot.spinDirection}, speed: ${shot.launchSpeed}`,
                {},
                e as Error,
            );
        }
        return level;
    }, [shot.launchSpeed, shot.spinDirection]);

    React.useEffect(() => {
        if (shot.spinLevel && shot.spinLevel > maxSpinLevel) {
            updateShot("spinLevel", maxSpinLevel);
        }
    }, [maxSpinLevel, shot.spinLevel, updateShot]);

    const firstBounce = React.useMemo(
        () =>
            launchData?.bounces.length
                ? launchData.bounces[0].timePosition.t
                : null,
        [launchData],
    );

    return (
        <Stack>
            <Tabs
                variant="fullWidth"
                allowScrollButtonsMobile
                value={tab}
                onChange={(_e: Event, value: TabValue) => setTab(value)}
                sx={{
                    "& .MuiTab-root": {
                        fontSize: 10,
                        minHeight: 24,
                        minWidth: 60,
                        p: 0,
                        pb: 1,
                    },
                    minHeight: 24,
                    mb: tab ? 2 : undefined,
                }}
            >
                <Tab label="Details" value="details" icon={<DetailsIcon />} />
                <Tab label="Speed" value="speed" icon={<SpeedIcon />} />
                <Tab label="Spin" value="spin" icon={<ThreeSixtyIcon />} />
                <Tab label="Aim" value="aim" icon={<SwapHorizIcon />} />
                <Tab label="Arc" value="arc" icon={<SwapVertIcon />} />
                <Tab label="Pad" value="pad" icon={<TimerIcon />} />
            </Tabs>
            {tab === "details" && (
                <ShotDetails
                    name={shot.name}
                    updateName={(name) => updateShot("name", name)}
                />
            )}
            {tab === "speed" && (
                <Speed
                    selectedSpeed={mps2mph(shot.launchSpeed)}
                    onSpeedChanged={(val) =>
                        updateShot("launchSpeed", mph2mps(val))
                    }
                    selectedSpeedVariation={shot.launchSpeedVariation}
                    onSpeedVariationChanged={(val) =>
                        updateShot("launchSpeedVariation", val)
                    }
                />
            )}
            {tab === "spin" && (
                <Spin
                    selectedSpin={shot.spinDirection}
                    onSpinChanged={(val) => updateShot("spinDirection", val)}
                    selectedSpinIntensity={shot.spinLevel ?? 1}
                    onSpinIntensityChanged={(val) =>
                        updateShot("spinLevel", val)
                    }
                    maxSpinLevel={maxSpinLevel}
                />
            )}
            {tab === "aim" && (
                <Aim
                    label="Aim"
                    onAimChanged={(val) =>
                        updateShot(
                            "pan",
                            playMode === "mirror" ? val * -1 : val,
                        )
                    }
                    selectedAim={
                        playMode === "mirror" ? shot.pan * -1 : shot.pan
                    }
                    onAimVariationChanged={(val) =>
                        updateShot("panVariation", val)
                    }
                    selectedAimVariation={shot.panVariation}
                />
            )}
            {tab === "arc" && (
                <Aim
                    label="Arc"
                    onAimChanged={(val) => updateShot("tilt", val)}
                    selectedAim={shot.tilt}
                    onAimVariationChanged={(val) =>
                        updateShot("tiltVariation", val)
                    }
                    selectedAimVariation={shot.tiltVariation}
                />
            )}
            {tab === "pad" && (
                <Pad
                    onIntervalChanged={(val) =>
                        updateShot(
                            "intervalOverride",
                            val > interval ? val : undefined,
                        )
                    }
                    selectedInterval={shot.intervalOverride || interval}
                    defaultInterval={interval}
                    firstBounce={firstBounce}
                />
            )}
            <Stack direction="row" justifyContent="space-between" py={3} px={1}>
                <IconButton onClick={onMoreOpen}>
                    <MoreHorizIcon />
                </IconButton>
                <Menu
                    anchorEl={moreAnchorEl}
                    open={!!moreAnchorEl}
                    onClose={onMoreClose}
                >
                    <MenuItem onClick={() => onMoreClick(onDuplicate)}>
                        <ListItemIcon>
                            <CopyAllIcon />
                        </ListItemIcon>
                        <ListItemText>Duplicate Shot</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={() => onMoreClick(onCancel)}>
                        <ListItemIcon>
                            <DeleteIcon color="error" />
                        </ListItemIcon>
                        <ListItemText sx={{ color: "error.main" }}>
                            Delete Shot
                        </ListItemText>
                    </MenuItem>
                </Menu>
                <Button onClick={onSave} startIcon={<DoneIcon />}>
                    Done
                </Button>
            </Stack>
            <Stack py={1}>
                <Button
                    disabled={
                        !canThrow || throwing || pairingStatus !== "paired"
                    }
                    onClick={() => {
                        makeShot(position.heightIn).catch((e) =>
                            logFetchError(e, "Error throwing test"),
                        );
                    }}
                    startIcon={<SportsTennisIcon />}
                >
                    Test
                </Button>
            </Stack>
            <Divider />
            <Stack direction="row" justifyContent="space-between" py={3} px={1}>
                <Button
                    startIcon={<ChevronLeftIcon />}
                    onClick={onBack}
                    disabled={!onBack}
                >
                    Back
                </Button>
                <Button
                    startIcon={<ChevronRightIcon />}
                    onClick={onNext}
                    disabled={!onNext}
                >
                    Next
                </Button>
            </Stack>
        </Stack>
    );
}

ShotControls.defaultProps = {
    onBack: undefined,
    onNext: undefined,
};
