import * as React from "react";
import { useNavigate } from "react-router-dom";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AppBar from "@mui/material/AppBar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import { useCurrentUser } from "../../../hooks/currentUser";

type CreateWorkoutDialogProps = DialogProps;

export default function CreateWorkoutDialog({
    open,
    onClose,
}: CreateWorkoutDialogProps): JSX.Element {
    const navigate = useNavigate();
    const { isAdmin } = useCurrentUser();

    // add to registry thingy... only show the ones have creators
    const cards = [
        {
            label: "Single Shot",
            overview: "Create a single shot workout",
            adminOnly: false,
            to: "/content/apps/workouts/plugin/edit/5",
        },
        {
            label: "Multi Shot",
            overview: "Create a workout with multiple shots",
            adminOnly: false,
            to: "/content/apps/workouts/plugin/edit/4",
        },
        {
            label: "Multi Level",
            overview: `
                Create a multi shot workout that can be arranged in a leveled structure.
                This allows the player to move through a skill progression.
            `,
            adminOnly: false,
            to: "/content/apps/workouts/plugin/edit/9",
        },
        {
            label: "Skill Building",
            overview: "Create an instructive workout with a video",
            adminOnly: true,
            to: "/content/apps/workouts/plugin/edit/11",
        },
    ];

    const cardsToRender = cards.filter((card) => isAdmin() || !card.adminOnly);

    return (
        <Dialog
            open={open}
            fullScreen
            PaperProps={{
                sx: {
                    backgroundColor: (t) => t.palette.primary.dark,
                },
            }}
        >
            <AppBar
                elevation={0}
                sx={{
                    backgroundColor: (t) => t.palette.primary.dark,
                }}
            >
                <Toolbar>
                    <IconButton
                        onClick={(e) => onClose && onClose(e, "backdropClick")}
                    >
                        <ArrowBackIcon sx={{ color: "white" }} />
                    </IconButton>
                    <Typography variant="h6">Create a New Workout</Typography>
                </Toolbar>
            </AppBar>
            <DialogContent
                sx={{
                    paddingTop: 8,
                }}
            >
                <Stack spacing={1}>
                    {cardsToRender.map((card) => (
                        <Card
                            key={card.to}
                            onClick={() => navigate(card.to)}
                            sx={{
                                backgroundColor: (t) => t.palette.primary.main,
                            }}
                        >
                            <CardContent>
                                <Typography
                                    variant="h4"
                                    color="white"
                                    textTransform="none"
                                >
                                    {card.label}
                                </Typography>
                                <Typography variant="body1" color="white">
                                    {card.overview}
                                </Typography>
                            </CardContent>
                        </Card>
                    ))}
                </Stack>
            </DialogContent>
        </Dialog>
    );
}
