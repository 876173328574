import * as React from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { CoordWithSys, PositionLike } from "../../../../util/position-types";
import {
    degToRad,
    physicsToCourt,
    radToDeg,
} from "../../../../util/positionUtil";
import { useSelectedSport } from "../../../common/context/sport";
import TrainerCourtPosition from "../../Position/TrainerCourtPosition";

import AccordionSlider from "./AccordionSlider";

interface Props {
    selectedPosition: PositionLike | undefined;
    onPositionChanged: (value: PositionLike) => void;
    disableYawControl?: boolean;
}

type CoordWithSysAndYaw = CoordWithSys & { yaw: number };

const sliderMarks = () => {
    const marks: { label: string; value: number }[] = [];
    for (let i = -75; i <= 75; i += 25) {
        marks.push({
            label: i === 0 ? "Forward" : `${i}°`,
            value: i,
        });
    }
    return marks;
};

export default function TrainerPosition({
    selectedPosition,
    onPositionChanged,
    disableYawControl = false,
}: Props): JSX.Element {
    const { manualPositions, selected: selectedSport } = useSelectedSport();

    function convertPosition(input: CoordWithSys): CoordWithSys {
        let { x, y } = input;
        if (selectedSport === "PLATFORM_TENNIS") {
            const court = physicsToCourt(input);
            x = court.x;
            y = court.y;
        }
        return { ...input, x, y };
    }

    return (
        <Stack spacing={1} pb={2}>
            <Typography variant="body2">
                Select the marker where you&apos;d like to position the trainer.
                Point the trainer directly forward.
            </Typography>
            <TrainerCourtPosition
                interactive
                mode="manual"
                points={manualPositions}
                selectedPosition={selectedPosition}
                scale={selectedSport === "PICKLEBALL" ? 0.6 : 0.8}
                size="HALF"
                onPointSelected={(selected) => {
                    if (selected?.length)
                        onPositionChanged({
                            ...convertPosition(selected[0]),
                            yaw: 0,
                        });
                }}
            />
            {!!selectedPosition && !disableYawControl && (
                <Box component="div" display="flex" justifyContent="center">
                    <AccordionSlider
                        min={-75}
                        max={75}
                        value={radToDeg(selectedPosition?.yaw ?? 0)}
                        track={false}
                        marks={sliderMarks()}
                        step={1}
                        onChange={(_, val) => {
                            if (selectedPosition) {
                                const updated: CoordWithSysAndYaw = {
                                    ...selectedPosition,
                                    yaw: degToRad(val as number),
                                    sys: "physics",
                                };
                                onPositionChanged(updated);
                            }
                        }}
                        sx={{ maxWidth: "90%" }}
                    />
                </Box>
            )}
        </Stack>
    );
}

TrainerPosition.defaultProps = {
    disableYawControl: false,
};
