import * as React from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import TuneIcon from "@mui/icons-material/Tune";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

import {
    SingleShotConfig,
    SingleShotParameters,
} from "@volley/shared/apps/single-shot-models";

import { CoordSys } from "../../../../../util/position-types";
import { useSelectedSport } from "../../../../common/context/sport";
import SideSelector from "../../Shared/SideSelector";
import ThrowCount from "../../Shared/ThrowCount";
import ThrowInterval from "../../Shared/ThrowInterval";
import { updateLocalParams } from "../../localWorkoutState";

import SingleShotDuplicateButton from "./SingleShotDuplicateButton";
import { WorkoutAction, WorkoutForm } from "./reducer";

interface Props {
    workout: WorkoutForm;
    dispatch: React.Dispatch<WorkoutAction>;
    onDelete: () => void;
    setMode: (mode: "play" | "edit") => void;
    saveWorkout: (
        asCopy: boolean,
        name?: string,
        description?: string,
    ) => Promise<void>;
    disabled?: boolean;
}

export default function PlayControls({
    workout,
    dispatch,
    onDelete,
    setMode,
    saveWorkout,
    disabled = false,
}: Props): JSX.Element {
    const { selected: selectedSport } = useSelectedSport();

    const updateParameter = React.useCallback(
        <K extends keyof SingleShotParameters>(
            property: K,
            value: SingleShotParameters[K],
        ) => {
            if (!workout) return;
            updateLocalParams({ [property]: value }, workout.appId, workout.id);
            dispatch({
                type: "updateParams",
                value: {
                    ...workout.parameters,
                    [property]: value,
                },
            });
        },
        [workout, dispatch],
    );

    const playerPositionForSide = React.useMemo(() => {
        if (workout) {
            const { playerPosition } =
                workout.config as unknown as SingleShotConfig;
            return {
                x: playerPosition?.x ?? 0,
                y: playerPosition?.y ?? 0,
                sys: (selectedSport === "PLATFORM_TENNIS"
                    ? "court"
                    : "physics") as CoordSys,
            };
        }

        return {
            x: 0,
            y: 0,
            sys: "physics" as CoordSys,
        };
    }, [workout, selectedSport]);

    return (
        <>
            <SideSelector
                disabled={disabled}
                playerPosition={playerPositionForSide}
                playMode={workout.parameters.playMode}
                setPlayMode={(p) => updateParameter("playMode", p)}
                workoutX={workout.positionX}
            />
            <ThrowInterval
                disabled={disabled}
                selectedThrowInterval={workout.parameters.intervalOverride}
                onUserThrowIntervalChanged={(updated) =>
                    updateParameter("intervalOverride", updated)
                }
            />
            <ThrowCount
                disabled={disabled}
                selectedThrowCount={workout.parameters.numberOfBalls}
                onUserThrowCountChanged={(updated) =>
                    updateParameter("numberOfBalls", updated)
                }
            />
            <Stack direction="row" justifyContent="space-around">
                <Button
                    startIcon={<TuneIcon />}
                    onClick={() => setMode("edit")}
                >
                    Edit
                </Button>
                {workout.isOwner ? (
                    <SingleShotDuplicateButton
                        defaultName={workout.name}
                        disabled={!workout.isDirty}
                        onConfirm={async (name, description) => {
                            const asCopy =
                                name !== workout.name || workout.id === 0;
                            await saveWorkout(asCopy, name, description);
                        }}
                        title={workout.id === 0 ? "New" : "Save"}
                    />
                ) : (
                    <SingleShotDuplicateButton
                        defaultName={`Copy of ${workout.name}`}
                        onConfirm={async (name, description) => {
                            const asCopy = true;
                            await saveWorkout(asCopy, name, description);
                        }}
                        title={workout.isDirty ? "Save As" : "Duplicate"}
                    />
                )}
                {workout.isOwner && (
                    <Button startIcon={<DeleteIcon />} onClick={onDelete}>
                        Delete
                    </Button>
                )}
            </Stack>
        </>
    );
}
PlayControls.defaultProps = { disabled: false };
