import * as React from "react";
import { useNavigate } from "react-router-dom";

import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import Fab from "@mui/material/Fab";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import type { OffsetResult } from "@volley/data";
import type { AppWorkoutListItem } from "@volley/data/dist/types/app-workout";
import {
    TagCategoryWithRelations,
    type Tag,
} from "@volley/data/dist/types/tag-category";

import fetchApi, { logFetchError } from "../util/fetchApi";

import useDialog from "./Dialog/useDialog";
import {
    FetchCriteria,
    HomeScreenCategoryDefinition,
    categories,
    type HomeScreenCategory,
} from "./HomeScreen";
import CreateWorkoutCard from "./Trainer/AppWorkouts/Shared/CreateWorkoutCard";
import { findApp } from "./Trainer/AppWorkouts/apps";
import HeroWrapper from "./Trainer/AppWorkouts/apps/shared/Hero";
import FilterState from "./Trainer/AppWorkouts/apps/shared/filterState";
import Loading from "./common/Loading";
import UserPage from "./common/UserPage";
import { useSelectedSport } from "./common/context/sport";
import { useCurrentUser } from "./hooks/currentUser";
import { useStatus } from "./hooks/status";

const INITIAL_FETCH = 500;

const HIDE_HEADINGS = ["FEATURED"];

type Action =
    | { type: "setLoading"; value: "init" | "fetching" | "done" }
    | { type: "addCategory"; value: HomeScreenCategory }
    | { type: "setTags"; value: Record<number, Tag[]> };

interface HomeScreenState {
    loading: "init" | "fetching" | "done";
    categories: HomeScreenCategory[];
}

function homeScreenReducer(
    state: HomeScreenState,
    action: Action,
): HomeScreenState {
    switch (action.type) {
        case "setLoading":
            return {
                ...state,
                loading: action.value,
                categories: action.value === "init" ? [] : state.categories,
            };
        case "addCategory":
            return {
                ...state,
                categories: [...state.categories, action.value],
            };
        default:
            return state;
    }
}

const initialState: HomeScreenState = {
    loading: "init",
    categories: [],
};

function buildTagFetchUrl(
    tagId: number,
    sport: string,
    limit: number,
    offset: number,
): string {
    // for the home screen, we want to sort based on how recently an item has been tagged
    const baseUrl =
        "/api/app-workouts/search?sortField=wt.updated_at&sortDirection=desc&user=false";
    const url = `${baseUrl}&tagIds=${tagId}&sport=${sport}&limit=${limit}&offset=${offset}`;
    return url;
}

function HorizontalScrollHome(): JSX.Element {
    const { currentUser } = useCurrentUser();
    const { setDialogType } = useDialog();
    const statusValue = useStatus();
    const navigate = useNavigate();
    const { selected: sport } = useSelectedSport();
    const [state, dispatch] = React.useReducer(homeScreenReducer, initialState);

    React.useEffect(() => {
        dispatch({ type: "setLoading", value: "init" });
    }, [sport]);

    React.useEffect(() => {
        async function fetchData() {
            const homeScreenCategories = await fetchApi<
                TagCategoryWithRelations[]
            >("/api/tags/list?onlyHomeScreen=true");
            const fetchCriteria: FetchCriteria = {
                limit: INITIAL_FETCH,
                offset: 0,
                sport,
                trainerId: statusValue.status?.clientId,
            };
            const tagCategories = homeScreenCategories.flatMap((c) =>
                c.tags.map((t) => {
                    const category: HomeScreenCategoryDefinition = {
                        sports: [
                            "PADEL",
                            "PICKLEBALL",
                            "PLATFORM_TENNIS",
                            "TENNIS",
                        ],
                        fetchResults: async ({
                            sport: selectedSport,
                            limit,
                            offset,
                        }: FetchCriteria) => {
                            const results = await fetchApi<
                                OffsetResult<AppWorkoutListItem>
                            >(
                                buildTagFetchUrl(
                                    t.id,
                                    selectedSport,
                                    limit,
                                    offset,
                                ),
                            );
                            return {
                                name: t.name,
                                label: t.label,
                                showIfEmpty: t.name === "FEATURED",
                                results,
                            };
                        },
                    };
                    return category;
                }),
            );
            const copy = [
                ...tagCategories,
                ...categories.filter((c) => c.sports.includes(sport)),
            ];
            const allResults = await Promise.allSettled(
                copy.map((c) => c.fetchResults(fetchCriteria)),
            );
            let homeClubId: number | null = null;
            allResults.forEach((result) => {
                if (result.status === "fulfilled") {
                    if (
                        result.value.results.count > 0 ||
                        result.value.showIfEmpty
                    ) {
                        if (
                            result.value.name === "trainer_club" &&
                            homeClubId !== null &&
                            result.value.results.result[0].provider?.id ===
                                homeClubId
                        ) {
                            // skip adding the trainer's club if it is the same as the home club
                            return;
                        }
                        dispatch({ type: "addCategory", value: result.value });

                        if (result.value.name === "home_club") {
                            homeClubId =
                                result.value.results.result[0].provider?.id ??
                                null;
                        }
                    }
                }
            });
        }
        if (state.loading === "init") {
            dispatch({ type: "setLoading", value: "fetching" });
            fetchData()
                .catch((e) => {
                    logFetchError(e);
                })
                .finally(() => dispatch({ type: "setLoading", value: "done" }));
        }
    }, [sport, currentUser, state.loading, statusValue.status?.clientId]);

    const navigateToApta = React.useCallback(
        async (playerIdArg?: string) => {
            let playerId = playerIdArg;
            if (!playerId) {
                try {
                    const res = await fetchApi<{ playerId: string }>(
                        "/api/pti/player-id/mine",
                    );
                    ({ playerId } = res);
                } catch {
                    setDialogType("APTAId");
                    return;
                }
            }

            navigate("/player-pti", {
                state: { aptaPlayerId: parseInt(playerId, 10) },
            });
        },
        [navigate, setDialogType],
    );

    if (state.loading !== "done") {
        return <Loading />;
    }

    return (
        <Stack
            spacing={2}
            sx={{
                overflowY: "scroll",
                overscrollBehavior: "contain",
                maxHeight: "calc(100svh - 61px)",
                paddingBottom: "100px",
                backgroundColor: (t) => t.palette.primary.dark,
                maskImage:
                    "linear-gradient(to bottom, rgba(0, 0, 0, 1) 90%, rgba(0, 0, 0, 0) 100%)",
            }}
        >
            {state.categories.map((c) => (
                <Stack key={c.name}>
                    {!HIDE_HEADINGS.includes(c.name) && (
                        <Stack direction="row">
                            {c.navUrl === undefined && (
                                <Typography
                                    variant="button"
                                    color="white"
                                    sx={{ textTransform: "none" }}
                                >
                                    {c.label}
                                </Typography>
                            )}
                            {c.navUrl && (
                                <Button
                                    endIcon={<KeyboardArrowRightIcon />}
                                    onClick={() => {
                                        if (c.setFilter) {
                                            c.setFilter();
                                        }
                                        navigate(
                                            c.navUrl ??
                                                "/content/apps/workouts/search",
                                        );
                                    }}
                                    color="primary"
                                    sx={{
                                        padding: 0,
                                        color: "white",
                                    }}
                                >
                                    <Stack
                                        direction="row"
                                        spacing={2}
                                        minWidth="80%"
                                        justifyContent="flex-start"
                                    >
                                        <Typography
                                            variant="button"
                                            color="white"
                                            sx={{
                                                textTransform: "none",
                                                overflowX: "hidden",
                                                textOverflow: "ellipsis",
                                                whiteSpace: "nowrap",
                                                display: "block",
                                            }}
                                        >
                                            {c.label}
                                        </Typography>
                                        <Typography
                                            variant="button"
                                            color="white"
                                            sx={{
                                                textTransform: "none",
                                                display: "block",
                                            }}
                                        >
                                            all
                                        </Typography>
                                    </Stack>
                                </Button>
                            )}
                        </Stack>
                    )}
                    <Stack
                        direction="row"
                        spacing={1}
                        sx={{
                            padding: "4px",
                            overflowX: "auto",
                            whiteSpace: "nowrap",
                            scrollbarWidth: "none",
                            msOverflowStyle: "none",
                            "&::-webkit-scrollbar": {
                                display: "none",
                            },
                            maskImage:
                                "linear-gradient(to right, rgba(0, 0, 0, 1) 90%, rgba(0, 0, 0, 0) 100%)",
                        }}
                    >
                        {c.name === "my_workouts" && <CreateWorkoutCard />}
                        {c.results.result.map((p) => {
                            const app = findApp(p.appId);
                            if (!app || app.CardComponent === null) {
                                return null;
                            }
                            if (c.name === "FEATURED") {
                                return (
                                    <app.HeroComponent
                                        key={p.id}
                                        workout={p}
                                        onClick={(id, navigateUrl) =>
                                            navigate(navigateUrl)
                                        }
                                    />
                                );
                            }
                            return (
                                <app.CardComponent
                                    key={p.id}
                                    workout={p}
                                    onClick={(id, navigateUrl) =>
                                        navigate(navigateUrl)
                                    }
                                />
                            );
                        })}
                        {c.name === "FEATURED" &&
                            sport === "PLATFORM_TENNIS" && (
                                <HeroWrapper
                                    onClick={() => navigateToApta()}
                                    workout={{
                                        appId: 1,
                                        id: 1,
                                        globalPlayCount: 0,
                                        name: "My PTI Stats",
                                        sportName: "PLATFORM_TENNIS",
                                        isFavorite: false,
                                        lastUserPlay: null,
                                        overview: "Platform Tennis Index",
                                        userPlayCount: 0,
                                        provider: {
                                            id: 4,
                                            label: "Volley",
                                            name: "VOLLEY",
                                        },
                                    }}
                                />
                            )}
                    </Stack>
                </Stack>
            ))}
            <Fab
                color="secondary"
                onClick={() => {
                    FilterState.clearFilter();
                    navigate("/content/apps/workouts/search");
                }}
                sx={{
                    position: "fixed",
                    bottom: "40px",
                    right: "20px",
                }}
            >
                <SearchIcon />
            </Fab>
        </Stack>
    );
}

export default function HorizontalScrollHomePage(): JSX.Element {
    return (
        <UserPage>
            <HorizontalScrollHome />
        </UserPage>
    );
}
