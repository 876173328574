import * as React from "react";

import { useTheme } from "@mui/material/styles";

interface NotchedOutlineProps {
    label?: string;
    centerLabel?: boolean;
    position?: "top" | "bottom";
    children: React.ReactNode;
    color?: string;
    eliptical?: boolean;
}

export default function NotchedOutline({
    label,
    centerLabel,
    eliptical,
    position,
    children,
    color,
}: NotchedOutlineProps): JSX.Element {
    const theme = useTheme();
    const nColor = color === "black" ? color : theme.palette.primary.light;
    const legendStyle: React.CSSProperties = {
        color: nColor,
        fontSize: "small",
    };
    if (position === "bottom") {
        legendStyle.position = "absolute";
        legendStyle.margin = "0px auto";
        legendStyle.position = "absolute";
        legendStyle.bottom = 0;
        legendStyle.left = "50%";
        legendStyle.transform = "translate(-50%, 50%)";
        legendStyle.background = "white";
    }
    if (centerLabel) {
        legendStyle.margin = "0 auto";
    }
    return (
        <fieldset
            style={{
                borderColor: nColor,
                borderRadius: eliptical
                    ? "50px / 50px"
                    : theme.shape.borderRadius,
                borderWidth: "1px",
                paddingBlockStart: "1px",
                paddingBlockEnd: "5px",
                paddingInlineStart: "5px",
                paddingInlineEnd: "5px",
                position: "relative",
            }}
        >
            {label && <legend style={legendStyle}>{label}</legend>}
            {children}
        </fieldset>
    );
}

NotchedOutline.defaultProps = {
    label: undefined,
    centerLabel: false,
    position: "top",
    color: undefined,
    eliptical: false,
};
