import * as React from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";

import SpinIllustration from "../Accordions/SpinIllustration";

import AccordionSlider from "./AccordionSlider";

interface SpinProps {
    selectedSpin: number;
    onSpinChanged: (value: number) => void;
    selectedSpinIntensity: number;
    onSpinIntensityChanged: (value: number) => void;
    maxSpinLevel: number;
    disabled?: boolean;
}

const spinOrientationMarks = [
    {
        value: -180,
        label: "Backspin",
    },
    {
        value: -135,
        header: "Left Backspin",
    },
    {
        value: -90,
        label: "Left",
    },
    {
        value: -45,
        header: "Left Topspin",
    },
    {
        value: 0,
        label: "Topspin",
    },
    {
        value: 45,
        header: "Right Topspin",
    },
    {
        value: 90,
        label: "Right",
    },
    {
        value: 135,
        header: "Right Backspin",
    },
    {
        value: 180,
        label: "Backspin",
    },
];

const spinIntensityMarks = [...Array(11).keys()].map((v) => ({
    value: v,
    label: v.toString(),
}));

function relativeToFull(relative: number): number {
    if (relative > 0) {
        return 360 - relative;
    }

    return -relative;
}

function fullToRelative(full: number, isNegative: boolean): number {
    if (full > 180) {
        return 360 - full;
    }

    if (full === 180 && !isNegative) {
        return full;
    }

    return -full;
}

export function relativeSpinToLabel(spin: number): string {
    const match = spinOrientationMarks.find((s) => s.value === spin);
    if (match) {
        return match.label ?? match.header;
    }

    return "Unknown";
}
export default function Spin({
    maxSpinLevel,
    selectedSpin,
    onSpinChanged,
    selectedSpinIntensity,
    onSpinIntensityChanged,
    disabled,
}: SpinProps): JSX.Element {
    const [isNegative, setIsNegative] = React.useState(false);
    const theme = useTheme();

    const intensitySliderStyles = React.useMemo(() => {
        const darkGrey = theme.palette.grey[500];
        const lightGrey = theme.palette.grey[200];
        const darkGreyBreak = maxSpinLevel * 10;
        const baseStyle: Record<string, unknown> = {
            zIndex: 1,
            "& .MuiSlider-rail": {
                opacity: 1,
                background: `linear-gradient(90deg, ${darkGrey} ${darkGreyBreak}%, ${lightGrey} 0%)`,
            },
            maxWidth: "90%",
        };
        [...Array(11).keys()].forEach((v) => {
            const key = `& .MuiSlider-markLabel[data-index="${v}"]`;
            let color = v < maxSpinLevel ? "primary.main" : "grey";
            let fontWeight = v < maxSpinLevel ? "400" : "100";
            if (v === maxSpinLevel) {
                color = "primary.light";
                fontWeight = "800";
            }
            baseStyle[key] = { color, fontWeight };
        });
        return baseStyle;
    }, [maxSpinLevel, theme]);

    const spinValue = React.useMemo(
        () => fullToRelative(selectedSpin, isNegative),
        [selectedSpin, isNegative],
    );

    return (
        <Stack spacing={1}>
            {maxSpinLevel < 1 && (
                <Typography variant="body2">
                    Lower the speed to enable spin for this shot.
                </Typography>
            )}
            <Box
                component="div"
                display="flex"
                justifyContent="center"
                flexGrow={1}
            >
                <AccordionSlider
                    disabled={disabled}
                    min={0}
                    max={10}
                    step={null}
                    marks={spinIntensityMarks}
                    value={selectedSpinIntensity}
                    onChange={(_, v) => {
                        const updated = Math.min(v as number, maxSpinLevel);
                        onSpinIntensityChanged(updated);
                    }}
                    onChangeCommitted={(_, v) => {
                        const updated = Math.min(v as number, maxSpinLevel);
                        onSpinIntensityChanged(updated);
                    }}
                    sx={intensitySliderStyles}
                />
            </Box>
            <Stack direction="row" spacing={4}>
                <Box component="div">
                    <SpinIllustration
                        imgScale={0.25}
                        spinIntensity={selectedSpinIntensity}
                        spinOrientation={-selectedSpin}
                    />
                </Box>
                <AccordionSlider
                    disabled={!selectedSpinIntensity}
                    step={null}
                    marks={spinOrientationMarks}
                    track={false}
                    min={-200}
                    max={200}
                    value={spinValue}
                    onChange={(_, v) => {
                        const full = relativeToFull(v as number);
                        onSpinChanged(full);
                        setIsNegative((v as number) < 0);
                    }}
                    onChangeCommitted={(_, v) => {
                        const full = relativeToFull(v as number);
                        onSpinChanged(full);
                        setIsNegative((v as number) < 0);
                    }}
                    sx={{
                        maxWidth: "70%",
                        "& .MuiSlider-markLabel": {
                            fontSize: "10px",
                        },
                    }}
                />
            </Stack>
        </Stack>
    );
}
Spin.defaultProps = { disabled: false };
