// Copyright Volley LLC, All Rights Reserved.
// Volley CONFIDENTIAL

import * as React from "react";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

import trainerFilledWhite from "../../../static/img/indicator-filled-white.png";
import trainerRingGreen from "../../../static/img/indicator-open-green.png";
import markerImage from "../../../static/img/indicator-p.png";
import {
    Coord,
    CoordWithSys,
    PositionLike,
} from "../../../util/position-types";
import type { Sport } from "../../common/context/sport";

import {
    clearCanvas,
    drawImage,
    drawImageRotated,
    drawImageWithLabel,
} from "./canvas";
import { CourtImages, CourtTable, IconDimensions } from "./constants";
import { CourtSize, ScaledDimensions } from "./types";
import { image2Projected, projected2Image } from "./util";

interface Props {
    interactive?: boolean;
    sport: Sport;
    size: CourtSize;
    scale: number;
    playerPositions: CoordWithSys[];
    trainerPosition?: PositionLike | undefined;
    trainerColor?: "white" | "green";
    onPointSelected?: (selected: CoordWithSys) => void;
}

type ExpectedImage = "court" | "marker" | "filled-white" | "ring-green";

type ImageStatus = "loading" | "loaded" | "failed";

type ImageState = Record<ExpectedImage, ImageStatus>;

type ImageAction = { image: ExpectedImage; value: ImageStatus };

function imageLoadedReducer(
    state: ImageState,
    action: ImageAction,
): ImageState {
    const updated = { ...state };
    updated[action.image] = action.value;
    return updated;
}

const defaultImageLoadedState: Record<ExpectedImage, ImageStatus> = {
    court: "loading",
    marker: "loading",
    "ring-green": "loading",
    "filled-white": "loading",
};

export default function PlayerCourtPosition({
    interactive,
    playerPositions,
    scale,
    size,
    sport,
    trainerPosition,
    trainerColor,
    onPointSelected,
}: Props): JSX.Element {
    const [imageLoadedState, dispatch] = React.useReducer(
        imageLoadedReducer,
        defaultImageLoadedState,
    );
    const [latestClick, setLatestClick] = React.useState<Coord | null>(null);

    const plotRef = React.useRef<HTMLCanvasElement>(null);
    const courtImageRef = React.useRef<HTMLImageElement>(null);
    const markerImageRef = React.useRef<HTMLImageElement>(null);
    const ringGreenImageRef = React.useRef<HTMLImageElement>(null);
    const filledWhiteImageRef = React.useRef<HTMLImageElement>(null);

    const showLoading = Object.values(imageLoadedState).some(
        (v) => v === "loading",
    );

    const showLoadingError = Object.values(imageLoadedState).some(
        (v) => v === "failed",
    );

    const courtImage = CourtImages[size][sport];

    const scaled = React.useMemo<ScaledDimensions>(() => {
        const original = CourtTable[sport];

        const height = original.height[size];
        const heightMeters = original.heightMeters[size];

        const yOffset = original.yOffset[size];

        return {
            width: original.width * scale,
            widthPPM: (original.width * scale) / original.widthMeters,
            height: height * scale,
            heightPPM: (height * scale) / heightMeters,
            xOffset: original.xOffset * scale,
            yOffset: yOffset * scale,
            trainerMarkerHeight: IconDimensions.trainerMarkerHeight * scale,
            trainerMarkerWidth: IconDimensions.trainerMarkerWidth * scale,
            trainerRingHeight: IconDimensions.trainerRingHeight * scale,
            trainerRingWidth: IconDimensions.trainerRingWidth * scale,
            trainerSolidHeight: IconDimensions.trainerSolidHeight * scale,
            trainerSolidWidth: IconDimensions.trainerSolidWidth * scale,
            markerHeight: IconDimensions.circleMarkerHeight * scale,
            markerWidth: IconDimensions.circleMarkerWidth * scale,
        };
    }, [scale, size, sport]);

    const drawPlayer = React.useCallback(
        (
            ctx: CanvasRenderingContext2D,
            pPosition: CoordWithSys,
            text: string,
        ) => {
            const marker = markerImageRef.current as HTMLImageElement;
            const selectedCoord = projected2Image(pPosition, scaled);

            drawImageWithLabel(
                ctx,
                marker,
                {
                    location: selectedCoord,
                    height: scaled.markerHeight,
                    width: scaled.markerWidth,
                    dropShadow: true,
                },
                {
                    text,
                },
            );
        },
        [scaled],
    );

    const handleCanvasClick = (
        event: React.MouseEvent<HTMLCanvasElement, MouseEvent>,
    ) => {
        const plot = plotRef.current as HTMLCanvasElement;
        const rect = plot.getBoundingClientRect();
        const x = Math.round(
            ((event.clientX - rect.left) / (rect.right - rect.left)) *
                scaled.width,
        );
        const y = Math.round(
            ((event.clientY - rect.top) / (rect.bottom - rect.top)) *
                scaled.height,
        );

        setLatestClick({ x, y });
    };

    React.useEffect(() => {
        // if there is a new click position, check to see if there are matching positions
        if (latestClick !== null && interactive) {
            const physicsPoint = image2Projected(latestClick, scaled);
            setLatestClick(null);

            if (physicsPoint.y > 0 && onPointSelected) {
                onPointSelected({ ...physicsPoint, sys: "physics" });
            }
        }
    }, [interactive, latestClick, scaled, onPointSelected]);

    React.useEffect(() => {
        const plot = plotRef.current as HTMLCanvasElement;
        const ctx = plot.getContext("2d") as CanvasRenderingContext2D;
        clearCanvas(ctx, scaled);

        if (imageLoadedState.court === "loaded") {
            const img = courtImageRef.current as HTMLImageElement;
            drawImage(ctx, img, {
                height: scaled.height,
                width: scaled.width,
                location: { x: 0, y: 0 },
            });
        }

        if (imageLoadedState.marker === "loaded" && playerPositions) {
            playerPositions.forEach((pPosition) => {
                drawPlayer(ctx, pPosition, "");
            });
        }

        if (
            imageLoadedState["filled-white"] === "loaded" &&
            trainerPosition &&
            trainerColor === "white"
        ) {
            const filled = filledWhiteImageRef.current as HTMLImageElement;
            const { x, y } = trainerPosition;
            const sys = sport === "PLATFORM_TENNIS" ? "court" : "physics";
            const coord = projected2Image({ x, y, sys }, scaled);

            drawImageRotated(ctx, filled, {
                height: scaled.trainerRingHeight,
                location: coord,
                width: scaled.trainerRingWidth,
                dropShadow: true,
                yaw: trainerPosition.yaw,
                xOffset: 0.5,
                yOffset: 2 / 3,
            });
        }
        if (
            imageLoadedState["ring-green"] === "loaded" &&
            trainerPosition &&
            trainerColor === "green"
        ) {
            const ring = ringGreenImageRef.current as HTMLImageElement;
            const { x, y } = trainerPosition;
            const sys = sport === "PLATFORM_TENNIS" ? "court" : "physics";
            const coord = projected2Image({ x, y, sys }, scaled);

            drawImageRotated(ctx, ring, {
                height: scaled.trainerRingHeight,
                location: coord,
                width: scaled.trainerRingWidth,
                dropShadow: true,
                yaw: trainerPosition.yaw,
                xOffset: 0.5,
                yOffset: 2 / 3,
            });
        }
    }, [
        imageLoadedState,
        scaled,
        trainerColor,
        playerPositions,
        sport,
        trainerPosition,
        drawPlayer,
    ]);

    if (showLoadingError) {
        return (
            <Box>
                <Typography variant="h3" align="center">
                    Unable to load required images.
                </Typography>
            </Box>
        );
    }

    return (
        <Box>
            <Box
                sx={{
                    width: `${scaled.width}px`,
                    height: `${scaled.height}px`,
                    margin: "auto",
                    textAlign: "center",
                    position: "relative",
                    background: showLoading ? "rgba(0, 0, 0, 0.2)" : "white",
                }}
            >
                {showLoading && (
                    <CircularProgress
                        sx={{
                            color: "info",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            marginTop: "-20px",
                            marginLeft: "-20px",
                        }}
                    />
                )}
                <img
                    alt="Court Diagram"
                    style={{ display: "none" }}
                    onLoad={() => dispatch({ image: "court", value: "loaded" })}
                    onError={() =>
                        dispatch({ image: "court", value: "failed" })
                    }
                    ref={courtImageRef}
                    src={courtImage}
                    width={scaled.width}
                    height={scaled.height}
                />
                <img
                    alt="Ring (green)"
                    style={{ display: "none" }}
                    onLoad={() =>
                        dispatch({ image: "ring-green", value: "loaded" })
                    }
                    onError={() =>
                        dispatch({ image: "ring-green", value: "failed" })
                    }
                    ref={ringGreenImageRef}
                    src={trainerRingGreen as string}
                    width={scaled.markerWidth}
                    height={scaled.markerHeight}
                />
                <img
                    alt="Filled (white)"
                    style={{ display: "none" }}
                    onLoad={() =>
                        dispatch({ image: "filled-white", value: "loaded" })
                    }
                    onError={() =>
                        dispatch({ image: "filled-white", value: "failed" })
                    }
                    ref={filledWhiteImageRef}
                    src={trainerFilledWhite as string}
                    width={scaled.markerWidth}
                    height={scaled.markerHeight}
                />
                <img
                    alt="Position Marker"
                    style={{ display: "none" }}
                    onLoad={() =>
                        dispatch({ image: "marker", value: "loaded" })
                    }
                    onError={() =>
                        dispatch({ image: "marker", value: "failed" })
                    }
                    ref={markerImageRef}
                    src={markerImage as string}
                    width={scaled.markerWidth}
                    height={scaled.markerHeight}
                />
                <canvas
                    title="Court"
                    ref={plotRef}
                    width={scaled.width}
                    height={scaled.height}
                    onClick={handleCanvasClick}
                />
            </Box>
        </Box>
    );
}

PlayerCourtPosition.defaultProps = {
    interactive: true,
    trainerPosition: undefined,
    trainerColor: "white",
    onPointSelected: undefined,
};
