import * as React from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import type { AppWorkoutWithRelations as AppWorkout } from "@volley/data/dist/types/app-workout";
import type { AppParameters } from "@volley/shared/app-models";
import type { PlayMode } from "@volley/shared/apps/app-common-models";
import type {
    CuratedWorkoutConfig,
    CuratedWorkoutParameters,
    CuratedWorkoutState,
} from "@volley/shared/apps/curated-workout-models";

import { determineNetSide } from "../../../util/positionUtil";
import Loading from "../../common/Loading";
import { VisualizerShot } from "../../common/Visualizer/types";
import { workoutToVisualizer } from "../../common/Visualizer/utils";
import { useSelectedSport } from "../../common/context/sport";
import { useCurrentUser } from "../../hooks/currentUser";
import { LiftModal, useLift } from "../../hooks/useLift";
import usePosition from "../../hooks/usePosition";

import LocalizationAccordion from "./Accordions/Localization";
import ParamsAccordion, {
    SMALLE_MAX_BALLS,
} from "./Accordions/ParamsAccordion";
import UserTrimAccordion from "./Accordions/UserTrim";
import WorkoutVisualizerAccordion from "./Accordions/VisualizerAccordion";
import CaptureToast from "./Shared/CaptureToast";
import PlayAppBar from "./Shared/PlayAppBar";
import ErrorDialog from "./apps/curated/play/ErrorDialog";
import ProblemsDialog from "./apps/curated/play/ProblemsDialog";
import useAppWorkouts from "./db";
import { PlayLocationState } from "./models";
import useAppWorkoutPlay from "./useAppWorkoutPlay";

export default function PlayShared(): JSX.Element {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const location = useLocation();
    const [expanded, setExpanded] = React.useState<string | false>(
        "Localization",
    );
    const { getWorkout, loading } = useAppWorkouts();
    const { isAdmin } = useCurrentUser();

    const [workout, setWorkout] = React.useState<AppWorkout | null>(null);
    const [workoutProblems, setWorkoutProblems] = React.useState<string[]>([]);
    const [error, setError] = React.useState<string | null>(null);

    const [hasLocalized, setHasLocalized] = React.useState(false);

    const numericId = React.useMemo(() => parseInt(id ?? "", 10), [id]);

    const { selected: selectedSport } = useSelectedSport();

    // User Configurable Params
    const [manualTrim, setManualTrim] = React.useState(0);
    const [selectedInterval, setSelectedInterval] = React.useState(2);
    const [selectedShotCount, setSelectedShotCount] = React.useState(60);
    const [playMode, setPlayMode] = React.useState<PlayMode>("standard");

    const {
        error: localizationError,
        improve,
        position: localizedPosition,
    } = usePosition();

    const workoutPosition = React.useMemo(() => {
        // localized position can be a cached value from a previous localization attempt
        // if we pass this along to the useAppWorkoutPlay hook, it can result in unintentional trim application
        // If the selected sport is not platform tennis, or the user hasn't localized for this workout yet,
        // or if localization reports errors or need for improvement... don't use the localized position value
        if (
            selectedSport !== "PLATFORM_TENNIS" ||
            !hasLocalized ||
            improve ||
            localizationError
        ) {
            return undefined;
        }

        return localizedPosition;
    }, [
        selectedSport,
        localizationError,
        improve,
        localizedPosition,
        hasLocalized,
    ]);

    const parameters: AppParameters = React.useMemo(() => {
        let shuffle = false;
        if (workout !== null) {
            shuffle =
                (workout.config as CuratedWorkoutConfig).randomize ?? false;
        }
        const updated = {
            numberOfBalls: selectedShotCount,
            intervalOverride: selectedInterval,
            initialDelay: 1,
            shuffle,
            playMode,
            mirrored: playMode === "mirror",
        };
        return updated;
    }, [playMode, selectedShotCount, selectedInterval, workout]);

    const {
        requestError,
        pause,
        start,
        stop,
        pauseDisabled,
        playDisabled,
        playState,
        statusText,
        playInitiated,
        workoutState,
        captureVideo,
        captureDisabled,
        captureStatus,
    } = useAppWorkoutPlay({
        workout,
        parameters,
        localizedPosition: workoutPosition,
    });

    const curatedWorkoutState =
        workoutState as unknown as CuratedWorkoutState | null;
    const ballsThrown = curatedWorkoutState?.currentShotNumber ?? 0;
    const totalBalls = React.useMemo(() => {
        const params = parameters as unknown as CuratedWorkoutParameters;
        return params.numberOfBalls === SMALLE_MAX_BALLS
            ? "All"
            : params.numberOfBalls;
    }, [parameters]);
    const shotSummary = playInitiated ? `${ballsThrown} of ${totalBalls}` : "";

    const {
        error: liftError,
        stop: stopLift,
        liftRange,
        checkForLift,
    } = useLift();

    const liftModalText =
        "The trainer arm is moving to the selected height to play your workout";

    const shouldFetch = React.useMemo(() => {
        if (error) {
            return false;
        }

        if (loading) {
            return false;
        }

        if (workout !== null && workout.id !== numericId) {
            return true;
        }

        if (workout === null && !loading) {
            return true;
        }

        return false;
    }, [numericId, workout, loading, error]);

    React.useEffect(() => {
        if (shouldFetch) {
            getWorkout(numericId)
                .then((w) => {
                    if (w === null) {
                        setError("Failed to load workout details.");
                    } else {
                        const loaded = { ...w };
                        setWorkout(loaded);
                        if (loaded.appId === 4 || loaded.appId === 6) {
                            const config = w.config as CuratedWorkoutConfig;
                            setSelectedShotCount(
                                config.shotCount || SMALLE_MAX_BALLS,
                            );
                            setSelectedInterval(config.interval || 2);
                        }
                    }
                })
                .catch(() => setError("Error fetching workout data"));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldFetch, getWorkout]);

    const handleLiftStop = React.useCallback(async () => {
        await stopLift();
        await stop();
    }, [stopLift, stop]);

    const handlePlayClicked = React.useCallback(async () => {
        if (workout !== null) {
            checkForLift();
            setExpanded(false);
            await start(manualTrim);
        }
    }, [manualTrim, workout, checkForLift, start]);

    const hasError = React.useMemo(
        () => requestError !== null || liftError !== null,
        [requestError, liftError],
    );

    React.useEffect(() => {
        if (hasError || expanded === false) {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
        }
    }, [hasError, expanded]);

    React.useEffect(() => {
        if (workout !== null && liftRange.min !== 0) {
            const problems: string[] = [];
            if (workout.appId === 6 || workout.appId === 4) {
                const cc = workout.config as CuratedWorkoutConfig;
                if (cc.playerPosition === undefined) {
                    problems.push("Player Position has not been set");
                }
                if (cc.shots === undefined || cc.shots?.length === 0) {
                    problems.push("No shots have been configured");
                }
            }
            if (
                workout.positionHeight < liftRange.min ||
                workout.positionHeight > liftRange.max
            ) {
                const { positionHeight: h } = workout;
                const err = `The workout height: ${h} is out the trainers range. [${liftRange.min}/${liftRange.max}]`;
                problems.push(err);
            }
            if (
                determineNetSide({
                    x: workout.positionX,
                    y: workout.positionY,
                }) !== "trainer"
            ) {
                problems.push("The Trainer Position is not set correctly");
            }
            setWorkoutProblems(problems);
        }
    }, [workout, liftRange]);

    const handlePanelChange =
        (panel: string) => (_: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    React.useEffect(() => {
        const resume = (location.state as PlayLocationState)?.resume;
        if (resume) {
            setExpanded(false);
        }
    }, [location.state]);

    const workoutTitle = React.useMemo(
        () =>
            playState === "playing" || playState === "paused"
                ? statusText
                : workout?.name,
        [playState, statusText, workout],
    );

    if (workout === null) {
        if (loading) {
            return <Loading />;
        }

        return (
            <ErrorDialog
                buttonText="Select Another"
                header="Problem Loading this Workout"
                text="Please select another workout."
                onClick={() => navigate("../", { replace: true })}
            />
        );
    }

    if (workoutProblems.length > 0 || error) {
        if (isAdmin()) {
            return (
                <ProblemsDialog
                    problems={workoutProblems}
                    workoutId={workout.id}
                />
            );
        }

        return (
            <ErrorDialog
                buttonText="Select Track"
                header="Problems With This Workout"
                text="Please Select Another Workout"
                onClick={() => navigate("../", { replace: true })}
            />
        );
    }

    const config = workout?.config as CuratedWorkoutConfig;

    let shotsToRender: VisualizerShot[] = [];
    if (workout) {
        const renderable = workoutToVisualizer(workout);
        shotsToRender = renderable?.shots ?? [];
    }

    return (
        <Stack
            sx={{
                minHeight: "900px",
            }}
        >
            <Stack direction="row">
                <Typography
                    color="primary"
                    variant="h3"
                    sx={{
                        lineHeight: "42px",
                        height: "42px",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                    }}
                >
                    {workoutTitle}
                </Typography>
            </Stack>
            {error !== null && (
                <Box component="div">
                    <Alert severity="error">
                        <AlertTitle>Error Loading Workout</AlertTitle>
                        {error}
                    </Alert>
                </Box>
            )}
            <LocalizationAccordion
                disabled={playState === "playing"}
                expanded={expanded === "Localization"}
                onChange={handlePanelChange("Localization")}
                trainerPosition={{
                    x: workout.positionX,
                    y: workout.positionY,
                    heightIn: workout.positionHeight,
                    yaw: workout.positionYaw,
                }}
                playMode={playMode}
                hasLocalized={hasLocalized}
                setHasLocalized={(val) => setHasLocalized(val)}
                setPlayMode={async (mode) => {
                    if (playState !== "stopped") {
                        await stop();
                    }
                    setPlayMode(mode);
                }}
                shots={shotsToRender}
                playerPosition={config.playerPosition}
                onLocalizeRequested={() => stop()}
            />
            {selectedSport !== "PLATFORM_TENNIS" && (
                <UserTrimAccordion
                    disabled={playState === "playing"}
                    onChange={handlePanelChange("Trim")}
                    expanded={expanded === "Trim"}
                    trim={manualTrim}
                    onTrimChanged={(val) => {
                        if (playState !== "stopped") {
                            void stop();
                        }
                        setManualTrim(val);
                    }}
                />
            )}
            <ParamsAccordion
                disabled={playState === "playing"}
                expanded={expanded === "Params"}
                onChange={handlePanelChange("Params")}
                selectedInterval={selectedInterval}
                selectedShotCount={selectedShotCount}
                onIntervalChanged={(i) => {
                    if (playState !== "stopped") {
                        void stop();
                    }
                    setSelectedInterval(i);
                }}
                onShotCountChanged={(s) => {
                    if (playState !== "stopped") {
                        void stop();
                    }
                    setSelectedShotCount(s);
                }}
                copyLevel={() => {}}
                setLevel={() => {}}
            />
            {selectedSport === "PLATFORM_TENNIS" && (
                <WorkoutVisualizerAccordion
                    trainerPosition={{
                        heightIn: workout.positionHeight,
                        x: workout.positionX,
                        y: workout.positionY,
                        yaw: workout.positionYaw,
                    }}
                    playMode={playMode}
                    selectedSport={selectedSport}
                    playerPosition={config.playerPosition}
                    shots={config.shots}
                />
            )}
            <PlayAppBar
                onPauseClicked={() => pause()}
                onPlayClicked={() => handlePlayClicked()}
                onRecordClicked={() => captureVideo()}
                pauseDisabled={pauseDisabled}
                playDisabled={playDisabled}
                playState={playState}
                playSummary={shotSummary}
                recordDisabled={captureDisabled}
                recordingStatus={captureStatus}
                showRecord
            />
            <LiftModal
                stop={handleLiftStop}
                targetHeight={
                    playInitiated ? workout.positionHeight : undefined
                }
                message={liftModalText}
            />
            <CaptureToast captureStatus={captureStatus} />
        </Stack>
    );
}
