import * as React from "react";
import { useNavigate } from "react-router-dom";

import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";

import type { AppWorkoutWithSummary } from "@volley/data/dist/types/app-workout";
import type { PhysicsModelName, Sport } from "@volley/physics/dist/models";
import type { PlayMode } from "@volley/shared/apps/app-common-models";
import type { ModuleConfig } from "@volley/shared/apps/module-models";

import { CoordSys } from "../../../../../../util/position-types";
import {
    degToRad,
    shouldImprovePosition,
} from "../../../../../../util/positionUtil";
import Label from "../../../../../common/Visualizer/Label";
import Overlay from "../../../../../common/Visualizer/Overlay";
import ResizableWorkoutVisualizer from "../../../../../common/Visualizer/ResizableWorkoutVisualizer";
import { moduleToVisualizer } from "../../../../../common/Visualizer/utils";
import { useSelectedSport } from "../../../../../common/context/sport";
import { useCurrentUser } from "../../../../../hooks/currentUser";
import { useLift } from "../../../../../hooks/useLift";
import usePosition, {
    PositionProximity,
} from "../../../../../hooks/usePosition";
import DetailsButton from "../../../Shared/DetailsButton";
import FavoriteButton from "../../../Shared/FavoriteButton";
import SideSelector from "../../../Shared/SideSelector";

import ModuleStartMoveTrainer from "./ModuleStartMoveTrainer";
import ModuleStartReady from "./ModuleStartReady";

interface Props {
    workout: AppWorkoutWithSummary;
}

export default function ModuleStart({ workout }: Props): JSX.Element {
    const navigate = useNavigate();
    const { selected: selectedSport } = useSelectedSport();
    const { currentUser } = useCurrentUser();
    const { safeHeight } = useLift();
    const [hasLocalized, setHasLocalized] = React.useState(false);
    const [hasBypassed, setHasBypassed] = React.useState(false);
    const config = workout.config as unknown as ModuleConfig;
    const [playMode, setPlayMode] = React.useState<PlayMode>("standard");

    const {
        error: localizationError,
        position: localizedPosition,
        isLocalizing,
        isVisionStarting,
        isVisionFaulted,
        cancel,
        updatePosition,
        localizationStatus,
        delta,
    } = usePosition();

    const positionProximity = React.useMemo((): PositionProximity => {
        if (selectedSport !== "PLATFORM_TENNIS" && hasLocalized) return "Good";
        if (isVisionStarting) return "Not Ready";
        if (isVisionFaulted) return "Unavailable";
        if (hasLocalized && !isLocalizing) {
            if (!localizedPosition || localizationError || !delta)
                return "Unsure";
            if (shouldImprovePosition(delta)) return "Improve";
        }
        return "Good";
    }, [
        isVisionStarting,
        isVisionFaulted,
        localizedPosition,
        localizationError,
        hasLocalized,
        isLocalizing,
        delta,
        selectedSport,
    ]);

    const yawProximity = React.useMemo((): PositionProximity => {
        if (positionProximity !== "Good") return positionProximity;
        if (selectedSport !== "PLATFORM_TENNIS") return "Good";
        if (!localizedPosition) return "Unsure";
        if (Math.abs(localizedPosition.yaw - workout.positionYaw) > degToRad(4))
            return "Improve";
        return "Good";
    }, [
        selectedSport,
        localizedPosition,
        workout.positionYaw,
        positionProximity,
    ]);

    const workoutPosition = React.useMemo(() => {
        if (
            selectedSport !== "PLATFORM_TENNIS" ||
            !hasLocalized ||
            localizationError
        ) {
            return undefined;
        }

        return localizedPosition;
    }, [selectedSport, localizationError, localizedPosition, hasLocalized]);

    const playerPosition = React.useMemo(() => {
        if (!config) return undefined;
        return config.playerPosition ?? { x: 0, y: 0 };
    }, [config]);

    const workoutForVisualizer = React.useMemo(() => {
        const converted = moduleToVisualizer(workout, playMode, selectedSport);
        if (localizedPosition && !hasBypassed) {
            converted.localized = {
                ...localizedPosition,
                heightIn: workout.positionHeight,
            };
        }
        return converted;
    }, [workout, localizedPosition, playMode, selectedSport, hasBypassed]);

    const playerPositionForSide = React.useMemo(() => {
        if (playerPosition) {
            return {
                x: playerPosition?.x ?? 0,
                y: playerPosition?.y ?? 0,
                sys: (selectedSport === "PLATFORM_TENNIS"
                    ? "court"
                    : "physics") as CoordSys,
            };
        }

        return {
            x: 0,
            y: 0,
            sys: "physics" as CoordSys,
        };
    }, [playerPosition, selectedSport]);

    const improveMode = React.useMemo(() => {
        if (positionProximity === "Improve") return "xy";
        if (positionProximity === "Good" && yawProximity === "Improve")
            return "yaw";
        return undefined;
    }, [positionProximity, yawProximity]);

    const combinedProximity = React.useMemo(() => {
        if (positionProximity === "Good") return yawProximity;
        return positionProximity;
    }, [positionProximity, yawProximity]);

    const [isFavorite, setIsFavorite] = React.useState(
        workout._count.favorites > 0,
    );

    const showMove =
        !hasLocalized ||
        (workout.sport.name === "PLATFORM_TENNIS" &&
            !hasBypassed &&
            (isLocalizing || combinedProximity !== "Good"));

    return (
        <>
            <ResizableWorkoutVisualizer
                positionProximity={hasBypassed ? "Good" : combinedProximity}
                workout={workoutForVisualizer}
                maxHeight={225}
                physicsModel={workout.physicsModelName as PhysicsModelName}
                sport={workout.sport.name as Sport}
                improveMode={improveMode}
                overlay={
                    <Overlay>
                        <FavoriteButton
                            workoutId={workout.id}
                            isFavorite={isFavorite}
                            onClick={() => {
                                setIsFavorite(!isFavorite);
                            }}
                        />
                        <Label text={workout.name} />
                        <DetailsButton workout={workout} />
                        {currentUser?.email === workout.createdBy && (
                            <IconButton
                                onClick={() => {
                                    navigate(
                                        `/content/apps/workouts/plugin/edit/${workout.appId}/${workout.id}`,
                                    );
                                }}
                                color="primary"
                            >
                                <EditIcon />
                            </IconButton>
                        )}
                    </Overlay>
                }
            />
            <SideSelector
                disabled={false}
                playerPosition={playerPositionForSide}
                playMode={playMode}
                setPlayMode={(p) => setPlayMode(p)}
                workoutX={workout.positionX}
            />
            {showMove ? (
                <ModuleStartMoveTrainer
                    localizationError={localizationError}
                    delta={delta}
                    hintText={combinedProximity}
                    localizationStatus={localizationStatus}
                    onCancel={() => cancel()}
                    positionRequested={hasLocalized}
                    sport={workout.sport.name as Sport}
                    improveMode={improveMode}
                    onDone={() => {
                        if (workout.sport.name === "PLATFORM_TENNIS") {
                            const oneShotLocalization = false;
                            setHasLocalized(true);
                            updatePosition(
                                {
                                    ...workoutForVisualizer.trainer,
                                    heightIn:
                                        workoutForVisualizer?.trainer
                                            .heightIn ?? safeHeight,
                                },
                                oneShotLocalization,
                            );
                        } else {
                            setHasLocalized(true);
                        }
                    }}
                    onBypass={() => {
                        setHasBypassed(true);
                        setHasLocalized(true);
                    }}
                />
            ) : (
                <ModuleStartReady
                    workout={workout}
                    playMode={playMode}
                    position={workoutPosition}
                />
            )}
        </>
    );
}
