import * as React from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import AccordionSlider from "./AccordionSlider";

export interface ThrowIntervalAfterSetProps {
    intervalAfterSet: number;
    setIntervalAfterSet: (throwIntervalAfterSet: number) => void;
    disabled?: boolean;
}

export default function ThrowIntervalAfterSet({
    intervalAfterSet: throwIntervalAfterSet,
    setIntervalAfterSet,
    disabled = false,
}: ThrowIntervalAfterSetProps): JSX.Element {
    function valueLabelFormat(value: number) {
        return `${value} sec`;
    }

    return (
        <Stack direction="row" spacing={0.5} alignItems="center">
            <Typography variant="caption" minWidth="30px">
                Time Between Sets
            </Typography>
            <Box component="div" flexGrow={1}>
                <AccordionSlider
                    disabled={disabled}
                    min={3}
                    max={20}
                    marks={[
                        { value: 3, label: "3 sec." },
                        { value: 7, label: "7 sec." },
                        { value: 15, label: "15 sec." },
                        { value: 20, label: "20 sec." },
                    ]}
                    getAriaValueText={(v) => valueLabelFormat(v)}
                    valueLabelFormat={(v) => valueLabelFormat(v)}
                    valueLabelDisplay="auto"
                    step={1}
                    value={throwIntervalAfterSet}
                    track={false}
                    onChange={(_, v) => setIntervalAfterSet(v as number)}
                    sx={{
                        display: "table",
                        margin: "0px auto 20px auto",
                        maxWidth: "80%",
                        ".MuiSlider-track": {
                            color: "primary.light",
                        },
                        "& .MuiSlider-valueLabel": {
                            fontSize: "32px",
                        },
                    }}
                />
            </Box>
        </Stack>
    );
}

ThrowIntervalAfterSet.defaultProps = {
    disabled: false,
};
