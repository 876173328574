import * as React from "react";

import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

import CloseableDialogTitle from "../common/CloseableDialogTitle";
import { useStatus } from "../hooks/status";

import useUnclosableDialog from "./common/useUnclosableDialog";
import useDialog from "./useDialog";

interface ReplaceBatteriesDialogProps {
    onSwapped: () => void;
    titleText?: string;
}

export default function ReplaceBatteriesDialog({
    onSwapped,
    titleText,
}: ReplaceBatteriesDialogProps): JSX.Element {
    useUnclosableDialog();
    const { setDialogType } = useDialog();
    const { status, startRapidPoll, removeRapidPollCheck } = useStatus();

    const onClickShutdown = React.useCallback(() => {
        setDialogType("Shutdown");
    }, [setDialogType]);

    React.useEffect(() => {
        startRapidPoll(1000, () => false, "batteries");
        return () => removeRapidPollCheck("batteries");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const disableButton = React.useMemo(() => {
        // we disable the continue button if we're in L6
        // or either battery is not present
        const L6 = status?.trainer.powerState === "L6";
        const missingBattery =
            !status?.trainer.battery.present?.a ||
            !status?.trainer.battery.present?.b;

        return L6 || missingBattery;
    }, [status]);

    return (
        <>
            <CloseableDialogTitle>{titleText}</CloseableDialogTitle>
            <DialogContent dividers>
                <DialogContentText mb={2}>
                    Please put in <strong>both</strong> charged batteries and
                    then click done.
                </DialogContentText>
                <Button
                    variant="contained"
                    onClick={() => onSwapped()}
                    fullWidth
                    disabled={disableButton}
                    sx={{ mb: 1 }}
                >
                    Done
                </Button>
                <DialogContentText>
                    No charged batteries available?
                </DialogContentText>
                <Button onClick={onClickShutdown} color="info" fullWidth>
                    Shutdown trainer.
                </Button>
            </DialogContent>
        </>
    );
}

ReplaceBatteriesDialog.defaultProps = {
    titleText: "Insert Both Charged Batteries",
};
