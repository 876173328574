import type { AppWorkoutWithRelations as AppWorkout } from "@volley/data/dist/types/app-workout";
import type {
    CuratedWorkoutConfig,
    CuratedWorkoutParameters,
    CuratedWorkoutShot,
    LeveledWorkoutConfig,
} from "@volley/shared/apps/curated-workout-models";
import type {
    ModuleConfig,
    ModuleParameters,
    ModuleShot,
} from "@volley/shared/apps/module-models";
import type { SingleShotShot } from "@volley/shared/apps/single-shot-models";
import type { JSONObject } from "@volley/shared/common-models";

import logger from "../../../log";
import generateUUID from "../../../util/uuid";

export type WorkoutShot = SingleShotShot | CuratedWorkoutShot;

export function isMultiLevelWorkout(workout: AppWorkout): boolean {
    return (
        (workout.config as unknown as LeveledWorkoutConfig).levels !== undefined
    );
}

export function isMultiShotWorkout(workout: AppWorkout): boolean {
    return (
        (workout.config as unknown as CuratedWorkoutConfig).shots !== undefined
    );
}

export function trimShot(trim: number, shot: WorkoutShot): WorkoutShot {
    return {
        ...shot,
        pan: shot.pan + trim,
    };
}

export default function trimShots(
    trim: number,
    shots: WorkoutShot[],
): WorkoutShot[] {
    return shots.map((s) => trimShot(trim, s));
}

export type KnownFeatures = "decimal-feed" | "shotIntervalOverride" | "modules";

export function trainerHasWorkoutFeatures(
    workout: AppWorkout,
    features: string[],
): KnownFeatures[] {
    const hasDecimalFeed = features.includes("decimal-feed");
    const hasShotIntervals = features.includes("shotIntervalOverride");
    const hasModules = features.includes("modules");
    if (hasDecimalFeed && hasShotIntervals && hasModules) {
        // short circuit here - if the trainer can handle both of these features, any workout should work
        return [] as KnownFeatures[];
    }

    if (!isMultiShotWorkout(workout)) {
        // the UI should prevent freeplay or quickstart shots from using these features
        return [] as KnownFeatures[];
    }

    const { config } = <{ config: CuratedWorkoutConfig }>workout;

    const missingFeatures: KnownFeatures[] = [];
    if (!hasDecimalFeed && config.interval) {
        if (config.interval % 1 !== 0) {
            missingFeatures.push("decimal-feed");
        }
    }

    if (!hasShotIntervals) {
        if (config.shots?.some((s) => s.intervalOverride)) {
            missingFeatures.push("shotIntervalOverride");
        }
    }

    if (!hasModules) {
        missingFeatures.push("modules");
    }

    return missingFeatures;
}

interface CompatibleWorkoutResult {
    workout: AppWorkout;
    missing: KnownFeatures[];
}
export function makeWorkoutCompatible(
    workout: AppWorkout,
    features: string[],
): CompatibleWorkoutResult {
    const missing = trainerHasWorkoutFeatures(workout, features);

    if (missing.length === 0) {
        return {
            workout,
            missing: [],
        };
    }

    const { config } = <{ config: CuratedWorkoutConfig }>workout;
    if (missing.includes("decimal-feed") && config.interval) {
        const { interval } = config;
        config.interval = Math.round(interval);
        logger.info(
            `Adjusting feed rate from ${interval} to ${config.interval}`,
        );
    }

    if (missing.includes("shotIntervalOverride") && config.shots) {
        const updated = config.shots.map((s) => ({
            ...s,
            intervalOverride: undefined,
        }));
        config.shots = updated;
    }

    const updated = {
        ...workout,
        config: config as JSONObject,
    };

    return {
        workout: updated,
        missing,
    };
}

function moduleShotToCuratedShot(shotArg: ModuleShot): CuratedWorkoutShot {
    const { launchSpeedVariation, tiltVariation, panVariation, name, ...shot } =
        shotArg;
    return {
        ...shot,
        launchSpeedVariance: launchSpeedVariation,
        tiltVariance: tiltVariation,
        panVariance: panVariation,
    };
}

export function moduleToCurated(
    config: ModuleConfig,
    params: ModuleParameters,
): [CuratedWorkoutConfig, CuratedWorkoutParameters] {
    const { sets, numberOfBalls, intervalAfterSet } = params;
    const { randomize, shots: moduleShots } = config;

    const moduleShotLength = moduleShots.length;
    let moduleShotIndex = 0;

    const shots: CuratedWorkoutConfig["shots"] = [];
    for (let i = 0; i < sets; i += 1) {
        const isLastSet = i === sets - 1;
        for (let j = 0; j < numberOfBalls; j += 1) {
            let shot;
            if (randomize) {
                shot =
                    moduleShots[Math.floor(Math.random() * moduleShotLength)];
            } else {
                if (moduleShotIndex >= moduleShotLength) {
                    moduleShotIndex = 0;
                }
                shot = moduleShots[moduleShotIndex];
                moduleShotIndex += 1;
            }
            if (shot) {
                const isLastShotInSet = j === numberOfBalls - 1;
                shots.push({
                    ...moduleShotToCuratedShot(shot),
                    id: generateUUID(),
                    intervalOverride:
                        !isLastSet && isLastShotInSet
                            ? intervalAfterSet
                            : shot.intervalOverride,
                });
            }
        }
    }

    const convertedConfig: CuratedWorkoutConfig = {
        randomize: false,
        interval: config.interval,
        playerPosition: config.playerPosition,
        shots,
        shotCount: shots.length,
    };
    const convertedParams: CuratedWorkoutParameters = {
        intervalOverride: params.interval,
        numberOfBalls: shots.length,
        playMode: params.playMode,
        shuffle: false,
        initialDelay: 1,
    };

    return [convertedConfig, convertedParams];
}
