// Copyright 2021 Volley LLC, All Rights Reserved.
// Volley CONFIDENTIAL

import * as React from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import BugReportIcon from "@mui/icons-material/BugReport";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import PowerSettingsIcon from "@mui/icons-material/PowerSettingsNew";
import SettingsIcon from "@mui/icons-material/Settings";
import SignalWifiOffIcon from "@mui/icons-material/SignalWifiOff";
import SportsTennisIcon from "@mui/icons-material/SportsTennis";
import TuneIcon from "@mui/icons-material/Tune";
import VisibilityIcon from "@mui/icons-material/Visibility";
import WifiIcon from "@mui/icons-material/Wifi";
import Accordian from "@mui/material/Accordion";
import AccordianDetails from "@mui/material/AccordionDetails";
import AccordianSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import type { NetworkConfig } from "@volley/shared/coach-models";

import theme from "../../../theme";
import { logFetchError, pairedFetchApi } from "../../../util/fetchApi";
import { useDebugMode } from "../../hooks/debugView";
import { useStatus } from "../../hooks/status";

import MotionStatus from "./MotionStatus";

function stringifyKey(key: string): string {
    return (key.match(/[A-Za-z][a-z]*/g) || [])
        .map((w) => `${w.charAt(0).toLocaleUpperCase()}${w.substring(1)}`)
        .join(" ");
}

function stringifyValue(v: unknown): string {
    if (typeof v === "object") {
        return JSON.stringify(v);
    }

    if (typeof v === "undefined" || v === null || Number.isNaN(v)) {
        return "";
    }

    if (typeof v === "number" || typeof v === "bigint") {
        return v.toString();
    }

    if (typeof v === "boolean") {
        return v ? "TRUE" : "FALSE";
    }

    return v as string;
}

type KV = { [k: string]: unknown };

function KeyValueTable({ data }: { data: KV }): JSX.Element {
    return (
        <Table>
            <TableBody>
                {Object.entries(data).map(([k, v]) => (
                    <TableRow key={k}>
                        <TableCell>{stringifyKey(k)}</TableCell>
                        <TableCell>
                            {typeof v === "object" && v !== null ? (
                                <KeyValueTable data={v as KV} />
                            ) : (
                                stringifyValue(v)
                            )}
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}

function CollapsableKeyValueTable({
    data,
    name,
    ok,
    settingsURL,
}: {
    data: KV;
    name: string;
    ok: boolean;
    settingsURL?: string | null;
}): JSX.Element {
    const navigate = useNavigate();
    return (
        <Accordian>
            <AccordianSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${name}-content`}
                id={`${name}-header`}
            >
                <Grid container>
                    <Grid item xs={8}>
                        <Typography variant="h2">{name}</Typography>
                    </Grid>
                    <Grid item xs={4} textAlign="right">
                        {settingsURL !== null ? (
                            <IconButton
                                onClick={() =>
                                    settingsURL && navigate(settingsURL)
                                }
                                size="large"
                            >
                                <SettingsIcon />
                            </IconButton>
                        ) : null}
                        {!ok && (
                            <Typography
                                variant="h2"
                                color="error.main"
                                align="center"
                                style={{ display: "inline" }}
                            >
                                ⬤
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            </AccordianSummary>
            <AccordianDetails>
                <KeyValueTable data={data} />
            </AccordianDetails>
        </Accordian>
    );
}

CollapsableKeyValueTable.defaultProps = {
    settingsURL: null,
};

function CollapsableNetworkConfig({
    configs,
    bridgeNetwork,
}: {
    configs: NetworkConfig[];
    bridgeNetwork: NetworkConfig | null;
}): JSX.Element {
    const connected = configs.filter(
        ({ status }) =>
            status === "Connected" || status === "ConnectedToInternet",
    );
    connected.sort((a, b) => {
        // Order the bridge network first
        if (bridgeNetwork !== null && a.device === bridgeNetwork.device) {
            return -1;
        }
        if (bridgeNetwork !== null && b.device === bridgeNetwork.device) {
            return -1;
        }
        // Order networks connected to the internet higher
        if (
            a.status === "ConnectedToInternet" &&
            b.status === "ConnectedToInternet"
        ) {
            return 0;
        }
        if (
            a.status === "ConnectedToInternet" &&
            b.status !== "ConnectedToInternet"
        ) {
            return -1;
        }
        return 1;
    });

    return (
        <Accordian>
            <AccordianSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="network-content"
                id="network-header"
            >
                <Grid container>
                    <Grid item xs={10}>
                        <Typography variant="h2">Network</Typography>
                    </Grid>
                    <Grid item xs={2} style={{ textAlign: "right" }}>
                        {connected[0]?.status === "ConnectedToInternet" ? (
                            <WifiIcon />
                        ) : (
                            <SignalWifiOffIcon />
                        )}
                    </Grid>
                </Grid>
            </AccordianSummary>
            <AccordianDetails>
                {connected.map((network) => (
                    <KeyValueTable key={network.name} data={{ ...network }} />
                ))}
            </AccordianDetails>
        </Accordian>
    );
}

export default function Status(): JSX.Element {
    const { status, error } = useStatus();
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);
    const retryCount = React.useRef(0);
    const recheckCount = React.useRef<number>();
    const { enabled, setEnabled } = useDebugMode();

    const clearFault = React.useCallback(
        async (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();

            retryCount.current += 1;

            // Start the rechecking of the status to see if the fault is cleared.
            recheckCount.current = 0;

            // Show the loading indicator
            setLoading(true);

            // Attempt to clear the fault
            try {
                await pairedFetchApi(
                    status?.clientId,
                    "api/clear-fault",
                    "POST",
                );
            } catch (ex) {
                logFetchError(ex, "Failed to clear fault from status dialog");
            }
        },
        [status?.clientId],
    );

    const enableDebug = React.useCallback(
        async (enable: boolean) => {
            try {
                await pairedFetchApi(
                    status?.clientId,
                    "api/enable-debug",
                    "POST",
                    { enable },
                );
                setEnabled(enable);
            } catch (e) {
                logFetchError(e, "failed to set debug mode");
            }
        },
        [setEnabled, status?.clientId],
    );

    const onClickPowerOff = React.useCallback(
        async (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();

            if (
                !window.confirm(
                    "Are you sure you want to perform a hard power off of this trainer?",
                )
            ) {
                return;
            }

            // Request a hard shutdown
            try {
                await pairedFetchApi(
                    status?.clientId,
                    "/api/shutdown",
                    "POST",
                    { soft: false },
                );
            } catch {
                logFetchError("Failed to shut dopwn from status dialog");
            }
        },
        [status?.clientId],
    );

    if (error?.message) {
        return <p>{error?.message}</p>;
    }

    if (!status || typeof status !== "object") {
        return <p>Loading...</p>;
    }

    const faultMessage =
        status.fault === null || status.fault.failures.length === 0
            ? ""
            : status.fault.failures[0].message;

    return (
        <Grid container direction="row" sx={{ flexGrow: 1 }} spacing={3}>
            {status.fault !== null && (
                <Grid item xs={12}>
                    <Box
                        sx={{
                            background: theme.palette.error.light,
                            textAlign: "center",
                            padding: "10px",
                        }}
                    >
                        <Typography align="center" variant="h6">
                            {faultMessage}
                        </Typography>
                        <Button
                            variant="contained"
                            size="large"
                            onClick={clearFault}
                            disabled={loading}
                        >
                            Retry
                        </Button>
                    </Box>
                </Grid>
            )}
            <Grid item xs={12} lg={6}>
                <Card>
                    <CardContent>
                        <Typography variant="h2" style={{ marginLeft: "1rem" }}>
                            System Status
                        </Typography>
                        <Box
                            component="div"
                            style={{ textAlign: "center", margin: "1rem" }}
                        >
                            <Button
                                variant="contained"
                                fullWidth
                                startIcon={<OpenInNewIcon />}
                                href="/admin"
                                target="_blank"
                            >
                                Admin Dashboard
                            </Button>
                        </Box>
                        <Box style={{ textAlign: "center", margin: "1rem" }}>
                            <Button
                                variant="contained"
                                fullWidth
                                startIcon={<BugReportIcon />}
                                component={RouterLink}
                                to="/content/issue"
                            >
                                Report an issue
                            </Button>
                        </Box>
                        <Box style={{ textAlign: "center", margin: "1rem" }}>
                            <Button
                                variant="contained"
                                fullWidth
                                startIcon={<SportsTennisIcon />}
                                onClick={() => navigate("/content/sports")}
                            >
                                Selected Sport
                            </Button>
                        </Box>
                        <Box style={{ textAlign: "center", margin: "1rem" }}>
                            <Button
                                variant="contained"
                                fullWidth
                                startIcon={<DisplaySettingsIcon />}
                                onClick={() => enableDebug(!enabled)}
                                color={enabled ? "error" : "primary"}
                            >
                                {enabled
                                    ? "Disable Diagnostics"
                                    : "Enable Diagnostics"}
                            </Button>
                        </Box>
                        <Box style={{ textAlign: "center", margin: "1rem" }}>
                            <Button
                                variant="contained"
                                fullWidth
                                startIcon={<TuneIcon />}
                                onClick={() => navigate("/content/shots")}
                            >
                                RPM Shot Test
                            </Button>
                        </Box>
                        <Box style={{ textAlign: "center", margin: "1rem" }}>
                            <Button
                                variant="contained"
                                fullWidth
                                startIcon={<VisibilityIcon />}
                                onClick={() =>
                                    navigate("/content/proximity-config")
                                }
                            >
                                Proximity Configuration
                            </Button>
                        </Box>
                        <Box style={{ textAlign: "center", margin: "1rem" }}>
                            <Button
                                variant="contained"
                                fullWidth
                                color="error"
                                startIcon={<PowerSettingsIcon />}
                                onClick={onClickPowerOff}
                            >
                                Power Off
                            </Button>
                        </Box>
                        <KeyValueTable
                            data={{
                                clientId: status.clientId,
                                hardwarePlatform: status.hardwarePlatform,
                                version: status.softwareVersion,
                                batteryLevel: `${status.trainer.battery.level}%`,
                                serverState: status.state,
                                currentHeight: status.trainer.height,
                                powerLevel: status.trainer.powerState,
                            }}
                        />
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} lg={6}>
                <CollapsableNetworkConfig
                    configs={status.system.networks}
                    bridgeNetwork={null}
                />
            </Grid>
            <Grid item xs={12} lg={6}>
                <CollapsableKeyValueTable
                    name="Web Connection"
                    data={status.web as unknown as KV}
                    ok={status.web.connection.state === "Online"}
                />
            </Grid>
            <Grid item xs={12} lg={6}>
                <CollapsableKeyValueTable
                    name="CAN"
                    data={status.trainer.can as unknown as KV}
                    ok={status.trainer.can.state === "Online"}
                />
            </Grid>
            <Grid item xs={12} lg={6}>
                <CollapsableKeyValueTable
                    name="CAN FD"
                    data={status.trainer.can as unknown as KV}
                    ok={status.trainer.can.state === "Online"}
                />
            </Grid>
            <Grid item xs={12} lg={6}>
                <CollapsableKeyValueTable
                    name="Vision"
                    data={status.vision as unknown as KV}
                    ok={status.vision.camera.state === "Online"}
                />
            </Grid>
            <Grid item xs={12} lg={6}>
                <CollapsableKeyValueTable
                    name="Lift"
                    data={status.trainer.lift as unknown as KV}
                    ok={status.trainer.lift.state === "Online"}
                    settingsURL={
                        status.trainer.lift.state !== "Disabled"
                            ? "/content/lift"
                            : undefined
                    }
                />
            </Grid>
            <Grid item xs={12} lg={6}>
                <CollapsableKeyValueTable
                    name="Pan"
                    data={status.trainer.pan as unknown as KV}
                    ok={status.trainer.pan.state === "Online"}
                />
            </Grid>
            <Grid item xs={12} lg={6}>
                <CollapsableKeyValueTable
                    name="Tilt"
                    data={status.trainer.tilt as unknown as KV}
                    ok={status.trainer.tilt.state === "Online"}
                />
            </Grid>
            <Grid item xs={12} lg={6}>
                <CollapsableKeyValueTable
                    name="Feed"
                    data={status.trainer.feed as unknown as KV}
                    ok={status.trainer.feed.state === "Online"}
                />
            </Grid>
            <Grid item xs={12} lg={6}>
                <CollapsableKeyValueTable
                    name="Top Wheel"
                    data={status.trainer.wheels.top as unknown as KV}
                    ok={status.trainer.wheels.top.state === "Online"}
                />
            </Grid>
            <Grid item xs={12} lg={6}>
                <CollapsableKeyValueTable
                    name="Left Wheel"
                    data={status.trainer.wheels.left as unknown as KV}
                    ok={status.trainer.wheels.left.state === "Online"}
                />
            </Grid>
            <Grid item xs={12} lg={6}>
                <CollapsableKeyValueTable
                    name="Right Wheel"
                    data={status.trainer.wheels.right as unknown as KV}
                    ok={status.trainer.wheels.right.state === "Online"}
                />
            </Grid>
            <Grid item xs={12} lg={6}>
                <Accordian>
                    <AccordianSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="motion-content"
                        id="motion-header"
                    >
                        <Grid container>
                            <Grid item xs={10}>
                                <Typography variant="h2">
                                    Motion Service
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                {status.trainer.serviceState === "Fault" && (
                                    <Typography
                                        variant="h2"
                                        color="error.main"
                                        align="center"
                                    >
                                        ⬤
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                    </AccordianSummary>
                    <MotionStatus />
                </Accordian>
            </Grid>
        </Grid>
    );
}
