import * as React from "react";

import Box from "@mui/material/Box";
import { buttonClasses, ButtonProps } from "@mui/material/Button";
import { styled } from "@mui/material/styles";

import theme from "../../theme";

function buttonRoot(
    variant: "apple" | "apple-black" | "volley" | "google" | "info",
) {
    let colors;
    switch (variant) {
        case "apple": {
            colors = {
                bg: theme.palette.common.white,
                text: theme.palette.grey[900],
                light: theme.palette.grey[100],
                dark: theme.palette.grey[300],
            };
            break;
        }
        case "apple-black": {
            colors = {
                bg: theme.palette.common.black,
                text: theme.palette.common.white,
                light: theme.palette.grey[800],
                dark: theme.palette.common.black,
            };
            break;
        }
        case "google": {
            colors = {
                bg: "#1578e5",
                text: theme.palette.common.white,
                light: "#4c99ee",
                dark: "#0f59ab",
            };
            break;
        }
        case "info": {
            colors = {
                bg: "#096ee5",
                text: theme.palette.common.white,
                dark: "#d5dBed",
                light: "#0a4995",
            };
            break;
        }
        case "volley":
        default: {
            colors = {
                bg: theme.palette.secondary.main,
                text: theme.palette.primary.main,
                light: theme.palette.secondary.light,
                dark: theme.palette.secondary.dark,
            };
            break;
        }
    }
    colors = {
        ...colors,
        disabled: "#d5dbed",
        disabledText: "#8792ae",
    };

    return styled("button")`
        background-color: ${colors.bg};
        padding: 12px;
        border-radius: 2pt;
        color: ${colors.text};
        height: 50px;
        font-weight: 600;
        font-size: 12pt;
        font-family: Gotham, sans-serif;
        text-transform: uppercase;
        cursor: pointer;
        box-shadow:
            0 2px 3px 2px rgba(61, 71, 82, 0.1),
            0 0 0 0 rgba(0, 127, 255, 0);
        border: none;
        width: 100%;

        &:hover {
            background-color: ${colors.light};
        }

        &:active {
            background-color: ${colors.dark};
        }

        &.${buttonClasses.focusVisible} {
            box-shadow:
                0 4px 20px 0 rgba(61, 71, 82, 0.1),
                0 0 0 5px rgba(0, 127, 255, 0.5);
            outline: none;
        }

        &.${buttonClasses.disabled} {
            color: ${colors.disabledText};
            background: ${colors.disabled};
            cursor: not-allowed;
            box-shadow: 0 0 0 0 rgba(0, 127, 255, 0);
        }
    `;
}

export function VolleyButton(props: ButtonProps): JSX.Element {
    const Component = buttonRoot("volley");
    return <Component {...props} />;
}

export function InfoButton(props: ButtonProps): JSX.Element {
    const Component = buttonRoot("info");
    return <Component {...props} />;
}

export function GoogleSignInButton(props: ButtonProps): JSX.Element {
    const Component = buttonRoot("google");
    return <Component {...props} />;
}

export function AppleSignInButton(props: ButtonProps): JSX.Element {
    const Component = buttonRoot("apple");
    return <Component {...props} />;
}

export function AppleBlackSignInButton(props: ButtonProps): JSX.Element {
    const Component = buttonRoot("apple-black");
    return <Component {...props} />;
}

export function ButtonOffsetContainer({
    children,
    sx,
}: {
    children: React.ReactNode;
    sx?: ButtonProps["sx"];
}): JSX.Element {
    return (
        <Box
            component="div"
            sx={{
                pt: 2,
                px: 2,
                mt: 8,
                position: "relative",
                ...sx,
            }}
        >
            <Box
                component="div"
                sx={{
                    width: "93%",
                    position: "absolute",
                    bottom: 0,
                    transform: "translateY(50%)",
                    textAlign: "center",
                }}
            >
                {children}
            </Box>
        </Box>
    );
}
ButtonOffsetContainer.defaultProps = { sx: {} };
