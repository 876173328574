import * as React from "react";

import Box from "@mui/material/Box";
import Container, { ContainerProps } from "@mui/material/Container";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";

import type { UserEvent } from "@volley/shared/events/user-event";

import logger from "../../log";
import { pairedFetchApi } from "../../util/fetchApi";
import useDialog from "../Dialog/useDialog";
import { Slug, TutorialsStepper } from "../Tutorials";
import { usePairingContext } from "../hooks/pairingStatus";
import { useStatus } from "../hooks/status";

import CloseableDialogTitle from "./CloseableDialogTitle";
import TopNavBar from "./TopNavBar";
import { trainerCompatibleWithSport, useSelectedSport } from "./context/sport";

const SLUGS: Slug[] = [
    "app-welcome",
    "app-menu",
    "app-support",
    "app-sport",
    "app-connect",
];

export default function UserPage({
    children,
    ...props
}: ContainerProps): JSX.Element {
    const { status } = useStatus();
    const { dialogType, setDialogType, batteryWarningDismissed } = useDialog();
    const [reconnectAttempts, setReconnectAttempts] = React.useState(0);
    const { status: pairingStatus } = usePairingContext();
    const { selected: selectedSport, hasSelected } = useSelectedSport();
    const [tutorialsPending, setTutorialsPending] = React.useState(false);
    const [tutorialsOpen, setTutorialsOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

    React.useEffect(() => {
        if (
            pairingStatus === "paired" &&
            (dialogType === "Device" || dialogType === null)
        ) {
            if (!trainerCompatibleWithSport(selectedSport, status)) {
                setDialogType("InvalidSportSelection");
            }
        }
    }, [pairingStatus, status, selectedSport, dialogType, setDialogType]);

    // setup event source for notifications
    React.useEffect(() => {
        const eventSrc = new EventSource("/api/events/user");
        eventSrc.onopen = (e: Event) => {
            logger.info("[UserPage] SOS event source opened", e);
        };

        eventSrc.onmessage = async (e: MessageEvent<string>) => {
            const parsedData = JSON.parse(e.data) as UserEvent;
            parsedData.timestamp = new Date(parsedData.timestamp);
            if (parsedData.data.type === "sos" && parsedData.userId) {
                logger.info("[UserPage] SOS event source message", e);
                setDialogType("NeedHelp");
                if (status?.clientId) {
                    // if we're in a session, pause potential workout
                    await pairedFetchApi(
                        status?.clientId,
                        "/api/apps/workouts/pause",
                        "POST",
                    );
                }
            } else if (parsedData.data.type === "close") {
                eventSrc.close();
            }
        };

        eventSrc.onerror = (e: Event) => {
            eventSrc?.close();
            logger.warn("[UserPage] SOS event source error", e);

            setTimeout(
                () => {
                    logger.info(
                        `[UserPage] Reconnecting to event source, attempt ${reconnectAttempts + 1}`,
                    );
                    setReconnectAttempts((current) => current + 1);
                },
                Math.min(reconnectAttempts + 1 * 1000, 10_000),
            ); // backoff but max 10s
        };

        return () => {
            eventSrc?.close();
        };
    }, [reconnectAttempts, setDialogType, status?.clientId]);

    React.useEffect(() => {
        if (
            status?.trainer.battery.level &&
            status?.ready !== "INIT" &&
            status?.trainer.battery.level <= 30 &&
            dialogType !== "NoConfirmReplaceBatteries" &&
            !batteryWarningDismissed
        ) {
            setDialogType("LowBatteryWarning");
        }
    }, [status, batteryWarningDismissed, dialogType, setDialogType]);

    React.useEffect(() => {
        if (!hasSelected) {
            logger.info("[UserPage] No sport selected, showing welcome dialog");
            setDialogType("SportSelectorWelcome");
            setTutorialsPending(true);
        }
    }, [hasSelected, setDialogType]);

    // After sport selection, open tutorials
    React.useEffect(() => {
        if (!dialogType && tutorialsPending) {
            setTutorialsPending(false);
            setTutorialsOpen(true);
        }
    }, [dialogType, tutorialsPending]);

    return (
        <Box
            component="div"
            sx={{
                backgroundColor: (t) => t.palette.primary.dark,
                minHeight: "100vh",
            }}
        >
            <TopNavBar />
            <Box component="main">
                <Container
                    {...props}
                    sx={{
                        padding: "0 4px 0 4px",
                        ml: { sm: "240px" },
                        width: { sm: "calc(100% - 240px" },
                    }}
                >
                    {children}
                </Container>
            </Box>
            <Dialog
                open={tutorialsOpen}
                fullScreen={fullScreen}
                sx={{ overflowY: "unset" }}
            >
                <CloseableDialogTitle onClose={() => setTutorialsOpen(false)}>
                    Getting Started
                </CloseableDialogTitle>
                <DialogContent sx={{ padding: 0, overflowY: "unset" }}>
                    <TutorialsStepper
                        slugs={SLUGS}
                        onDone={() => setTutorialsOpen(false)}
                    />
                </DialogContent>
            </Dialog>
        </Box>
    );
}
