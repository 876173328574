import * as React from "react";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { pairedFetchApi } from "../../../util/fetchApi";
import { useStatus } from "../../hooks/status";
import ConfirmDialog from "../ConfirmDialog";
import LowPowerDialog from "../LowPowerDialog";
import LowerHeadDialog from "../LowerHeadDialog";
import PowerUpDialog from "../PowerUpDialog";
import ReplaceBatteriesDialog from "../ReplaceBatteriesDialog";
import useDialog from "../useDialog";

const enum ReplaceStep {
    Confirm,
    PauseWorkout,
    Start,
    PowerDown,
    ReplaceBatteries,
    PowerUp,
    Calibrate,
    Done,
}

interface PauseWorkoutProps {
    onPaused: () => void;
}
function PauseWorkoutDialog({
    onPaused,
}: PauseWorkoutProps): JSX.Element | null {
    const { status } = useStatus();
    const [requestedPause, setRequestedPause] = React.useState(false);

    React.useEffect(() => {
        if (status?.workouts.playState === "playing" && !requestedPause) {
            void pairedFetchApi(
                status?.clientId,
                "/api/apps/workouts/pause",
                "POST",
            );
            setRequestedPause(true);
        } else {
            onPaused();
        }
    }, [
        onPaused,
        requestedPause,
        status?.clientId,
        status?.workouts.playState,
    ]);

    React.useEffect(() => {
        if (requestedPause && status?.workouts.playState !== "playing") {
            onPaused();
        }
    }, [status?.workouts.playState, onPaused, requestedPause]);

    return (
        <>
            <DialogTitle>Stopping workout</DialogTitle>
            <DialogContent>
                <DialogContentText>Pausing workout...</DialogContentText>
                <Box component="div" sx={{ textAlign: "center", mt: 2 }}>
                    <CircularProgress size={80} color="info" />
                </Box>
            </DialogContent>
        </>
    );
}

export interface ReplaceBatteryFlowProps {
    initialStep?: ReplaceStep;
}

export default function ReplaceBatteryFlow({
    initialStep,
}: ReplaceBatteryFlowProps): JSX.Element | null {
    const { setDialogType } = useDialog();
    const [step, setStep] = React.useState(initialStep ?? ReplaceStep.Confirm);
    const [error, setError] = React.useState("");

    if (error) {
        setDialogType("Shutdown");
    }

    if (step === ReplaceStep.Confirm) {
        return (
            <ConfirmDialog
                title="Replace batteries?"
                contentText="Are you sure you'd like to replace the batteries?"
                buttonLabel="Replace"
                onConfirm={() => setStep(ReplaceStep.PauseWorkout)}
                onClose={() => setDialogType("Device")}
            />
        );
    }

    if (step === ReplaceStep.PauseWorkout) {
        return (
            <PauseWorkoutDialog onPaused={() => setStep(ReplaceStep.Start)} />
        );
    }

    if (step === ReplaceStep.Start) {
        return (
            <LowerHeadDialog
                onLowered={() => {
                    setTimeout(() => {
                        // TODO - cbley - for some reason when we try to power down
                        // too soon after lowering, it never powers down
                        // here we'll just try to wait a second after lowering
                        // before powering down
                        setStep(ReplaceStep.PowerDown);
                    }, 1000);
                }}
                reason="battery-replace"
                onStopped={() => setDialogType(null)}
                onError={setError}
            />
        );
    }

    if (step === ReplaceStep.PowerDown) {
        return (
            <LowPowerDialog
                onPoweredDown={() => setStep(ReplaceStep.ReplaceBatteries)}
            />
        );
    }

    if (step === ReplaceStep.ReplaceBatteries) {
        return (
            <ReplaceBatteriesDialog
                onSwapped={() => setStep(ReplaceStep.PowerUp)}
            />
        );
    }

    if (step === ReplaceStep.PowerUp) {
        return <PowerUpDialog onPoweredUp={() => setStep(ReplaceStep.Done)} />;
    }

    if (step === ReplaceStep.Done) {
        setDialogType(null);
    }

    return null;
}

export function NoConfirmReplaceBatteryFlow(): JSX.Element | null {
    return <ReplaceBatteryFlow initialStep={ReplaceStep.PauseWorkout} />;
}

ReplaceBatteryFlow.defaultProps = {
    initialStep: ReplaceStep.Confirm,
};
