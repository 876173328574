import * as React from "react";

import CheckIcon from "@mui/icons-material/Check";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

import {
    Sport,
    trainerCompatibleWithSport,
    useSelectedSport,
} from "./common/context/sport";
import { useStatus } from "./hooks/status";

export interface SportPickerProps {
    showOnlyCompatible?: boolean;
    afterSportChanged?: (sport: Sport) => void;
    limitedList?: Record<string, string>;
}

export default function SportPicker({
    showOnlyCompatible,
    afterSportChanged,
    limitedList,
}: SportPickerProps): JSX.Element {
    const { selected, hasSelected, available, updateSelected } =
        useSelectedSport();
    const { status } = useStatus();

    return (
        <Stack spacing={1} width="100%">
            {Object.keys(limitedList ?? available)
                .filter((a) => {
                    if (showOnlyCompatible) {
                        return trainerCompatibleWithSport(a as Sport, status);
                    }

                    return true;
                })
                .map((k) => {
                    let label = limitedList
                        ? limitedList[k as Sport]
                        : available[k as Sport];
                    if (label === "Pickleball" && status) {
                        label += " (Requires Pickleball Trainer)";
                    }
                    return (
                        <Button
                            key={k}
                            onClick={() => {
                                updateSelected(k as Sport);
                                if (afterSportChanged) {
                                    afterSportChanged(k as Sport);
                                }
                            }}
                            color={
                                hasSelected && selected === k
                                    ? "primary"
                                    : "secondary"
                            }
                            endIcon={
                                !hasSelected || selected !== k ? (
                                    <ChevronRightIcon />
                                ) : (
                                    <CheckIcon
                                        sx={{
                                            color: (t) =>
                                                t.palette.secondary.main,
                                        }}
                                    />
                                )
                            }
                            variant="contained"
                        >
                            {label}
                        </Button>
                    );
                })}
        </Stack>
    );
}

SportPicker.defaultProps = {
    afterSportChanged: undefined,
    showOnlyCompatible: false,
    limitedList: undefined,
};
