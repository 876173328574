/* eslint-disable import/no-cycle */
import { format } from "date-fns-tz/fp";

export { default as getEnv } from "./getEnv";
export { default as fetchApi } from "./fetchApi";

export const round = (num: number, places: number) =>
    Math.round((num + Number.EPSILON) * 10 ** places) / 10 ** places;

export const usDateFormat = format("MM/dd/yyyy hh:mm:ss aa (z)");
export const verboseDateFormat = format("LLLL d, yyyy");
export const terseTimeFormat = format("MM/dd/yyyy hh:mm aa");
export const terseDateFormat = format("MM/dd/yyyy");
export const isoDateFormat = format("yyyy-MM-dd");
