import * as React from "react";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import type {
    CoordLike,
    PositionLike,
    CoordSys,
    CoordWithSys,
} from "../../../../util/position-types";
import { physicsToCourt } from "../../../../util/positionUtil";
import { useSelectedSport } from "../../../common/context/sport";
import PlayerCourtPosition from "../../Position/PlayerCourtPosition";

interface Props {
    selectedTrainerPosition?: PositionLike | undefined;
    selectedPlayerPosition?: CoordLike | undefined;
    onPlayerPositionChanged: (value: CoordLike) => void;
}

export default function PlayerPosition({
    selectedTrainerPosition,
    selectedPlayerPosition,
    onPlayerPositionChanged,
}: Props): JSX.Element {
    const { selected: selectedSport } = useSelectedSport();
    const sys: CoordSys =
        selectedSport === "PLATFORM_TENNIS" ? "court" : "physics";

    const handlePositionChange = React.useCallback(
        (updated?: CoordWithSys) => {
            if (updated !== undefined) {
                if (selectedSport === "PLATFORM_TENNIS") {
                    const court = physicsToCourt(updated);
                    onPlayerPositionChanged(court);
                } else {
                    onPlayerPositionChanged(updated);
                }
            }
        },
        [selectedSport, onPlayerPositionChanged],
    );

    return (
        <Stack spacing={1}>
            <Typography variant="body2">
                Press the spot on the court opposite the trainer where the
                player will start when the workout begins.
            </Typography>
            <PlayerCourtPosition
                playerPositions={
                    selectedPlayerPosition
                        ? [{ ...selectedPlayerPosition, sys }]
                        : []
                }
                scale={selectedSport === "PICKLEBALL" ? 0.4 : 0.5}
                size="FULL"
                sport={selectedSport}
                trainerPosition={selectedTrainerPosition}
                onPointSelected={handlePositionChange}
            />
        </Stack>
    );
}

PlayerPosition.defaultProps = {
    selectedTrainerPosition: undefined,
    selectedPlayerPosition: undefined,
};
