import { ModuleParameters } from "@volley/shared/apps/module-models";

export type ParameterAction<K extends keyof ModuleParameters> = {
    type: K;
    value: ModuleParameters[K];
};

export default function parametersReducer<K extends keyof ModuleParameters>(
    state: ModuleParameters,
    action: ParameterAction<K>,
): ModuleParameters {
    return { ...state, [action.type]: action.value };
}
