import * as React from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { ResponsiveIndexEntry } from "@volley/shared/apps/responsive-models";

export interface WorkoutPickerProps {
    fullWidth: boolean;
    options: ResponsiveIndexEntry[];
    selected: string | null;
    onChange: (fileSuffix: string) => void;
}

export default function WorkoutPicker({
    fullWidth,
    options,
    selected,
    onChange,
}: WorkoutPickerProps): JSX.Element {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement>();

    const icon = React.useMemo(() => {
        if (options.length === 0) {
            return undefined;
        }

        if (anchorEl) {
            return (
                <KeyboardArrowUpIcon
                    sx={{
                        fontSize: "18px",
                    }}
                />
            );
        }

        return (
            <KeyboardArrowDownIcon
                sx={{
                    fontSize: "18px",
                }}
            />
        );
    }, [anchorEl, options]);

    const buttonText = React.useMemo(() => {
        if (selected) {
            return selected;
        }

        if (options.length) {
            return "Select Workout";
        }

        return "No Workouts Available";
    }, [selected, options]);

    return (
        <>
            <Button
                fullWidth={fullWidth}
                onClick={(e) => {
                    if (anchorEl) {
                        setAnchorEl(undefined);
                    } else {
                        setAnchorEl(e.currentTarget);
                    }
                }}
                disableElevation
                sx={{
                    color: "primary.main",
                    fontSize: "18px",
                }}
                startIcon={icon}
            >
                {buttonText}
            </Button>
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                open={anchorEl !== undefined}
                onClose={() => setAnchorEl(undefined)}
            >
                {options.map((o) => (
                    <MenuItem
                        key={o.fileSuffix}
                        onClick={() => {
                            onChange(o.fileSuffix);
                            setAnchorEl(undefined);
                        }}
                        sx={{
                            color: "primary.main",
                        }}
                    >
                        {o.displayName}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}
