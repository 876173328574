import * as React from "react";

import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

interface Props {
    buttonText: string;
    header: string;
    text: string;
    onClick: () => void;
    onDismiss?: () => void;
}

export default function ErrorDialog({
    buttonText,
    header,
    text,
    onClick,
    onDismiss,
}: Props): JSX.Element {
    return (
        <Dialog open fullWidth maxWidth={false}>
            <DialogTitle variant="h4" color="primary.main">
                {header}
                {onDismiss && (
                    <IconButton
                        aria-label="close"
                        onClick={() => onDismiss()}
                        sx={{
                            position: "absolute",
                            right: 6,
                            top: 6,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                )}
            </DialogTitle>
            <DialogContent dividers>
                <Stack spacing={3}>
                    <Typography variant="body2">{text}</Typography>
                    <Box component="div">
                        <Button
                            variant="contained"
                            color="secondary"
                            fullWidth
                            onClick={() => onClick()}
                        >
                            {buttonText}
                        </Button>
                    </Box>
                </Stack>
            </DialogContent>
        </Dialog>
    );
}

ErrorDialog.defaultProps = {
    onDismiss: undefined,
};
