import * as React from "react";

import SpeedIcon from "@mui/icons-material/Speed";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import ThreeSixtyIcon from "@mui/icons-material/ThreeSixty";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import { convert } from "@volley/physics";
import type { PlayMode } from "@volley/shared/apps/app-common-models";
import type {
    SingleShotConfig as Config,
    SingleShotShot as Shot,
} from "@volley/shared/apps/single-shot-models";

import logger from "../../../../../../log";
import { mph2mps, mps2mph } from "../../../../util/conversions";
import Aim from "../../../Shared/Aim";
import Speed from "../../../Shared/Speed";
import Spin from "../../../Shared/Spin";
import { WorkoutForm } from "../reducer";

type TabValue = "speed" | "spin" | "aim" | "arc" | "height";

interface Props {
    workout: WorkoutForm;
    playMode: PlayMode;
    updateShot: <K extends keyof Shot>(property: K, value: Shot[K]) => void;
    disabled?: boolean;
}

export default function ShotControls({
    workout,
    playMode,
    updateShot,
    disabled,
}: Props): JSX.Element {
    const [tab, setTab] = React.useState<TabValue>("speed");

    const config = workout.config as unknown as Config;
    const { shot } = config;

    const maxSpinLevel = React.useMemo(() => {
        let level = 0;
        try {
            level = convert.maxspinlevel(shot.spinDirection, shot.launchSpeed);
        } catch (e) {
            logger.error(
                `Failed to get max spin level for axis: ${shot.spinDirection}, speed: ${shot.launchSpeed}`,
                {},
                e as Error,
            );
        }
        return level;
    }, [shot.launchSpeed, shot.spinDirection]);

    React.useEffect(() => {
        if (shot.spinLevel && shot.spinLevel > maxSpinLevel) {
            updateShot("spinLevel", maxSpinLevel);
        }
    }, [maxSpinLevel, shot.spinLevel, updateShot]);

    return (
        <Stack>
            <Tabs
                variant="fullWidth"
                allowScrollButtonsMobile
                value={tab}
                onChange={(_e, value: TabValue) => setTab(value)}
                sx={{
                    "& .MuiTab-root": {
                        fontSize: 10,
                        minHeight: 24,
                        minWidth: 60,
                        p: 0,
                        pb: 1,
                    },
                    minHeight: 24,
                    mb: tab ? 2 : undefined,
                }}
            >
                <Tab label="Speed" value="speed" icon={<SpeedIcon />} />
                <Tab label="Spin" value="spin" icon={<ThreeSixtyIcon />} />
                <Tab label="Aim" value="aim" icon={<SwapHorizIcon />} />
                <Tab label="Arc" value="arc" icon={<SwapVertIcon />} />
            </Tabs>
            {tab === "speed" && (
                <Speed
                    selectedSpeed={mps2mph(shot.launchSpeed)}
                    onSpeedChanged={(val) =>
                        updateShot("launchSpeed", mph2mps(val))
                    }
                    selectedSpeedVariation={shot.launchSpeedVariation}
                    onSpeedVariationChanged={(val) =>
                        updateShot("launchSpeedVariation", val)
                    }
                    disabled={disabled}
                />
            )}
            {tab === "spin" && (
                <Spin
                    selectedSpin={shot.spinDirection}
                    onSpinChanged={(val) => updateShot("spinDirection", val)}
                    selectedSpinIntensity={shot.spinLevel ?? 1}
                    onSpinIntensityChanged={(val) =>
                        updateShot("spinLevel", val)
                    }
                    maxSpinLevel={maxSpinLevel}
                    disabled={disabled}
                />
            )}
            {tab === "aim" && (
                <Aim
                    label="Aim"
                    onAimChanged={(val) =>
                        updateShot(
                            "pan",
                            playMode === "mirror" ? val * -1 : val,
                        )
                    }
                    selectedAim={
                        playMode === "mirror" ? shot.pan * -1 : shot.pan
                    }
                    onAimVariationChanged={(val) =>
                        updateShot("panVariation", val)
                    }
                    selectedAimVariation={shot.panVariation}
                    disabled={disabled}
                />
            )}
            {tab === "arc" && (
                <Aim
                    label="Arc"
                    onAimChanged={(val) => updateShot("tilt", val)}
                    selectedAim={shot.tilt}
                    onAimVariationChanged={(val) =>
                        updateShot("tiltVariation", val)
                    }
                    selectedAimVariation={shot.tiltVariation}
                    disabled={disabled}
                />
            )}
        </Stack>
    );
}
ShotControls.defaultProps = { disabled: false };
